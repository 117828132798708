import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

/* eslint-disable */
const Svg = () => (
  // eslint-disable-next-line
  <svg
    viewBox='0 0 1024 1024'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    p-id='10075'
    width='16'
    height='16'
  >
    <path
      d='M290.2016 335.7184l56.576 56.576-120.576 120.576 120.576 120.576-56.576 56.5248L141.312 541.184a39.9872 39.9872 0 0 1-0.768-55.808l0.768-0.768 148.8896-148.8384zM864 760.6784v79.9744H160v-79.9744zM864 183.296v80.0256H160V183.3472z'
      p-id='10076'
    ></path>
    <path d='M202.752 474.0096l664.064 1.9968-0.256 79.9744-664.0128-1.9968z' p-id='10077'></path>
  </svg>
)

const IconComponent = (props: Partial<CustomIconComponentProps> | any) => (
  <Icon component={Svg} {...props} />
)

export default IconComponent
