import { ConfigProvider, theme } from 'antd'
import { RouterProvider, createHashRouter, createRoutesFromElements } from 'react-router-dom'
import { StyleProvider } from '@ant-design/cssinjs'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'
import locale from 'antd/locale/zh_CN'
import { useMount } from 'ahooks'
import routes from './router'
import { customTheme } from './config/theme'
import { getUnitAliasList, getUnitCollection, refreshSession } from './api/common/base'
import useGlobalStore from './store/useGlobalStore'

dayjs.locale('zh-cn')

function App() {
  const setState = useGlobalStore.setState
  const router = createHashRouter(createRoutesFromElements(routes))

 

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        // algorithm: theme.defaultAlgorithm,
        token: customTheme,
        // algorithm: [theme.compactAlgorithm],
        components: {
          Button: {
            controlHeight: 36,
            colorPrimary: '#288DFF',
            paddingInline: '15',
            algorithm: true, // 启用算法
          },
        },
      }}
    >
      {/* <StyleProvider hashPriority='high'> */}
      <RouterProvider router={router} />
      {/* </StyleProvider> */}
    </ConfigProvider>
  )
}

export default App
