import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

/* eslint-disable */
const Svg = () => (
  // eslint-disable-next-line
  <svg
    width='16px'
    height='16px'
    viewBox='0 0 19 19'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <polygon id='path-1' points='0 0.0002 16.157 0.0002 16.157 14.392 0 14.392'></polygon>
    </defs>
    <g id='数字资产' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='04' transform='translate(-405.000000, -148.000000)'>
        <g id='侧边栏icon数据字典/未选中' transform='translate(405.375000, 148.437500)'>
          <rect id='矩形' fill='currentColor' opacity='0' x='0' y='0' width='18' height='18'></rect>
          <g id='编组' transform='translate(0.125671, 0.776000)'>
            <g
              transform='translate(0.662391, 3.727688)'
              id='路径'
              stroke='currentColor'
              strokeLinecap='round'
              strokeWidth='1.5'
            >
              <path d='M0,9.375 L1.41868984,12.0594726 C1.45332863,12.1250168 1.52138127,12.1660238 1.59551551,12.1660238 L12.2835088,12.1660238 L12.2835088,12.1660238 C12.5095088,12.1660238 12.7065088,12.0160238 12.7655088,11.7990238 L16.5765088,0'></path>
            </g>
            <g>
              <mask id='mask-2' fill='white'></mask>
              <g id='Clip-5'></g>
              <path
                d='M5.2991895,0 L14.9395303,0 C15.1870229,0 15.3879395,0.20707652 15.3879395,0.462224375 C15.3879395,0.504749017 15.3824599,0.548198109 15.3705876,0.589798302 L11.3139,12.970016 C11.2581914,13.167848 11.0819328,13.3046664 10.8819295,13.3046664 L1.08085547,13.3046664 C0.83244957,13.3046664 0.632446289,13.0975899 0.632446289,12.842442 C0.632446289,12.7906729 0.640665602,12.7379793 0.657104228,12.689908 L4.87543826,0.308765882 C4.93845299,0.123876132 5.10831879,0 5.2991895,0'
                id='Fill-4'
                stroke='currentColor'
                strokeWidth='1.5'
              ></path>
            </g>
            <g
              id='编组-10'
              transform='translate(4.871952, 3.301200)'
              stroke='currentColor'
              strokeLinecap='round'
              strokeWidth='1.5'
            >
              <line x1='0.965842665' y1='0.5' x2='7.01549922' y2='0.5' id='Stroke-6'></line>
              <line x1='0' y1='3.4868' x2='6.15959771' y2='3.4868' id='Stroke-8'></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const IconComponent = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Svg} {...props} />
)

export default IconComponent
