import React, { useState } from 'react'
import {
  Form,
  Input,
  Select,
  Tag,
  Button,
  Space,
  Divider,
  DatePicker,
  Cascader,
  Col,
  Row,
} from 'antd'
import { FieldTypeEnum } from '@/config/enum'
import styles from './index.module.less'
import { useSetState } from 'ahooks'

const { Compact } = Space
const { RangePicker } = DatePicker

const options: any[] = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
        children: [
          {
            value: 'zhonghuamen',
            label: 'Zhong Hua Men',
          },
        ],
      },
    ],
  },
]

const SearchForm = ({ config, onSubmit }: any) => {
  const [form] = Form.useForm()
  const [tags, setTags] = useState([])
  const [state, setState] = useSetState({
    tags: [],
    showMore: false,
  })

  const onFinish = values => {
    setTags(Object.entries(values).map(([key, value]) => ({ key, value })))
    onSubmit(values)
  }

  const removeTag = key => {
    setTags(tags.filter(tag => tag.key !== key))
    form.setFieldsValue({ [key]: undefined })
  }

  const renderField = (field, { layout }) => {
    const { label, initialValue, name, componentType } = field
    const width = layout === 'horizontal' ? field.width : '100%'
    if (componentType === FieldTypeEnum.INPUT) {
      return (
        <Form.Item name={name} initialValue={initialValue}>
          <Input
            width={width}
            addonBefore={label}
            placeholder={`请输入${label}`}
            onChange={field.onChange}
          />
        </Form.Item>
      )
    }
    if (componentType === FieldTypeEnum.SELECT) {
      return (
        <Form.Item name={name}>
          {/* <div className={styles.innerFieldWrap}>
            <div className={styles.addon}>{label}</div>
            <Select
              style={{ width }}
              // className='w-full'
              placeholder={`请选择${label}`}
              options={field.options}
              onChange={field.onChange}
            />
          </div> */}
           <Select
              style={{ width }}
              // className='w-full'
              placeholder={`请选择${label}`}
              options={field.options}
              onChange={field.onChange}
            />
        </Form.Item>
      )
    }
    if (componentType === FieldTypeEnum.DATETIME) {
      return (
        <Form.Item name={name}>
          <div className={styles.innerFieldWrap}>
            <div className={styles.addon}>{label}</div>
            <DatePicker
              style={{ width }}
              // placeholder={`请选择${label}`}
              onChange={field.onChange}
            />
          </div>
        </Form.Item>
      )
    }
    if (componentType === FieldTypeEnum.DATETIME_RANGE) {
      return (
        <Form.Item name={name}>
          <div className={styles.innerFieldWrap}>
            <div className={styles.addon}>{label}</div>
            <RangePicker
              style={{ width }}
              // placeholder={`请选择${label}`}
              // onChange={field.onChange}
            />
          </div>
        </Form.Item>
      )
    }
    // 级联选择器
    if (componentType === FieldTypeEnum.CASCADER) {
      return (
        <Form.Item name={name}>
          <div className={styles.innerFieldWrap}>
            <div className={styles.addon}>{label}</div>
            <Cascader
              options={field.options}
              // onChange={field.onChange}
              placeholder={field.placeholder}
            />
          </div>
        </Form.Item>
      )
    }
    return null
  }

  const handleFormChange = (changedValues, allValues) => {
    console.log(changedValues, allValues)
  }

  const handleMore = () => {
    setState({
      showMore: !state.showMore,
    })
  }

  const handleSearch = async () => {
    const values = form.getFieldsValue()
    console.log(values)
  }

  // FormLayout = 'horizontal' | 'inline' | 'vertical'
  return (
    <div className={styles.container}>
      <Form form={form} onFinish={onFinish} onValuesChange={handleFormChange}>
        <div className={styles.fieldList}>
          <div className={styles.first}>
            {config.slice(0, 3).map(field => (
              <div className={styles.fieldItem} key={field.key}>
                {renderField(field, { layout: 'horizontal' })}
              </div>
            ))}
            <Button onClick={handleSearch} className={styles.fieldItem} type='primary' ghost>
              搜索
            </Button>
            <Button onClick={handleMore} className={styles.fieldItem}>
              高级搜索
            </Button>
          </div>
          <div className={styles.end}>
            <Row className='w-full' gutter={15}>
              {state.showMore &&
                config.slice(3).map(field => (
                  <Col span={6} key={field.key}>
                    {renderField(field, { layout: 'vertical' })}
                  </Col>
                ))}
            </Row>
          </div>
        </div>

        {/* <Form.Item>
          <Button onClick={() => setShowMore(!showMore)}>{showMore ? '收起' : '更多'}</Button>
          <Button type='primary' htmlType='submit' style={{ marginLeft: '10px' }}>
            搜索
          </Button>
        </Form.Item> */}
      </Form>
      <div>
        {state.tags.map(tag => (
          <Tag key={tag.key} closable onClose={() => removeTag(tag.key)}>
            {`${tag.key}：${tag.value}`}
          </Tag>
        ))}
      </div>
    </div>
  )
}

// 使用方式：
const config = [
  {
    label: '姓名',
    value: '',
    key: 'name',
    name: 'name',
    width: 200,
    componentType: FieldTypeEnum.INPUT,
    defaultValue: '',
  },
  {
    label: '年纪',
    value: '',
    key: 'age',
    name: 'age',
    width: 200,
    componentType: FieldTypeEnum.INPUT,
    defaultValue: '',
  },
  {
    label: '性别',
    key: 'sex',
    width: 200,
    name: 'sex',
    value: 'all',
    componentType: FieldTypeEnum.SELECT,
    options: [
      { value: 'all', label: '全部' },
      { value: 'male', label: '男' },
      { value: 'female', label: '女' },
    ],
  },
  {
    label: '创建日期',
    width: 200,
    key: 'createTime',
    name: 'createTime',
    componentType: FieldTypeEnum.DATETIME,
  },
  {
    label: '创建日期范围',
    width: 250,
    key: 'createTimeRange',
    name: 'createTimeRange',
    componentType: FieldTypeEnum.DATETIME_RANGE,
  },
  {
    label: '选择城市',
    width: 250,
    key: 'choseCity',
    name: 'choseCity',
    componentType: FieldTypeEnum.CASCADER,
    options,
  },
  {
    label: '选择城市2',
    width: 250,
    key: 'choseCity2',
    name: 'choseCity2',
    componentType: FieldTypeEnum.CASCADER,
    options,
  },
  {
    label: '选择城市3',
    width: 250,
    key: 'choseCity3',
    name: 'choseCity3',
    componentType: FieldTypeEnum.CASCADER,
    options,
  },
]

const App = () => {
  const handleSearchChange = values => {
    console.log('搜索组件点击')
    console.log(values)
  }
  return (
    <div
      style={{
        width: '1200px',
        padding: '50px',
      }}
    >
      <Compact>
        <Button>请输入</Button>
        <Input placeholder='请输入' />
      </Compact>
      <Divider> 搜索组件 </Divider>
      <SearchForm
        config={config}
        onChange={handleSearchChange}
        onSubmit={values => console.log(values)}
      />
    </div>
  )
}

export default App
