import { Modal } from 'antd'
import antdStore from '@/store/antd'
import { getStorage, isValidNumber, isValidString, removeStorage } from './tools'
import { traverseTree } from './tree'
import { FieldTypeEnum } from '@/config/enum'
import { AuthConfig, PersonalPath, PublicPath } from '@/config/auth'
import { DefaultHeaderKey } from '@/config'

// 重新登录确认框
export const loginAgainConfirm = () => {
  // 单例模式，只弹一次
  if (window.loginModalVisible) {
    return
  }
  window.loginModalVisible = true
  // console.log(antdStore)
  Modal.warning({
    title: '登录失效',
    content: '请重新登录',
    okText: '去登录',
    className: 'calc-not-login-modal',
    centered: true,
    onOk: () => {
      window.loginModalVisible = false
      window.location.href = `${window.location.origin}${window.location.pathname}#/login`
      removeStorage('token')
    },
  })
}

// 获取默认展开key
export function getDefaultOpenKeys(items: any[], selectedKey: string): string[] {
  let parentKey = ''
  items.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        if (child.key === selectedKey) {
          parentKey = item.key
        }
      })
    }
  })
  return [parentKey]
}

interface ProvChildrenProps {
  code?: string
  name?: string
  type?: number
  parentCode?: string
}
export interface ProvinceCityOptionProps {
  code: string
  name: string
  type?: number
  children?: ProvChildrenProps[]
}
/**
 * 将地区接口数组数据转成树结构
 * @param data
 * @returns
 */
export const reginTreeDataTranslate = (data = []) => {
  if (!data?.length) return []
  const obj = {}
  const tree: ProvinceCityOptionProps[] = []
  for (const item of data) {
    obj[item.code] = item
  }
  for (const item of data) {
    const parentCode = item?.parentCode
    // 如果parentCode存在, 将当前item作为它的子节点
    if (obj[parentCode]) {
      ; (obj[parentCode].children || (obj[parentCode].children = [])).push(item)
    } else {
      // 否则将当前item作为根节点
      tree.push(item)
    }
  }
  return tree
}

// 是否是有效的口径值
export function isValidCaliber(v: any) {
  return isValidString(v) || isValidNumber(v)
}

// 重新刷新页面。不是跳转
export function reloadPage(path) {
  window.location.href = `${window.location.origin}${window.location.pathname}#${path}`
}

// 根据当前路由，获取当前的顶部菜单
export function getHeaderMenuKeyByPath(path: string) {
  // let res = ''
  // Object.keys(menuKeysMap).forEach(key => {
  //   const menuKeys = menuKeysMap[key]
  //   traverseTree(menuKeys, node => {
  //     if (node.path === path) {
  //       res = key
  //     }
  //   })
  // })
  const res = AuthConfig.find(i => i.path === path)?.headMenuKey || DefaultHeaderKey
  return res
}

// 根据title计算列宽度
export function calculateWidthField(field) {
  const { fieldTitle, fieldName, fieldType } = field
  // 为汉字设置宽度
  const chineseCharWidth = 20
  // 为英文字母和数字设置宽度
  const alphabetCharWidth = 10
  // 为其他字符设置宽度
  const otherCharWidth = 10
  if (fieldType === FieldTypeEnum.INPUT) {
    return 200
  }
  if (fieldType === FieldTypeEnum.INPUTNUMBER) {
    return 200
  }
  if (fieldType === FieldTypeEnum.DATETIME) {
    return 100
  }
  if (fieldType === FieldTypeEnum.SELECT_MULTI) {
    return 200
  }
  if (fieldType === FieldTypeEnum.DATETIME_RANGE) {
    return 200
  }
  let width = 0
  const arr = fieldTitle.split('')
  for (let char of arr) {
    if (/[\u4e00-\u9fa5]/.test(char)) {
      // 如果是汉字
      width += chineseCharWidth
    } else if (/[a-zA-Z0-9]/.test(char)) {
      // 如果是英文字母或数字
      width += alphabetCharWidth
    } else {
      // 其他字符
      width += otherCharWidth
    }
  }

  // 如果需要，您还可以在最终结果上添加一个固定的额外宽度
  const extraPadding = 10
  return width + extraPadding
}

// 根据title计算列宽度
export function calculateWidthByTitle(title) {
  // 为汉字设置宽度
  const chineseCharWidth = 20
  // 为英文字母和数字设置宽度
  const alphabetCharWidth = 10
  // 为其他字符设置宽度
  const otherCharWidth = 10

  let width = 0
  const arr = title.split('')
  for (let char of arr) {
    if (/[\u4e00-\u9fa5]/.test(char)) {
      // 如果是汉字
      width += chineseCharWidth
    } else if (/[a-zA-Z0-9]/.test(char)) {
      // 如果是英文字母或数字
      width += alphabetCharWidth
    } else {
      // 其他字符
      width += otherCharWidth
    }
  }

  // 如果需要，您还可以在最终结果上添加一个固定的额外宽度
  const extraPadding = 10
  return width + extraPadding
}

// 把字段列表分类
export function getClassifyFieldArray(arr) {
  const obj = {}
  const classifyObj = {}
  arr.forEach(item => {
    // 有的接口是放在fieldClassify里面的
    classifyObj[item.classifyId] = item?.fieldClassify?.classifyName || item.classifyName
    if (obj[item.classifyId]) {
      obj[item.classifyId].push(item)
    } else {
      obj[item.classifyId] = [item]
    }
  })
  return { obj, classifyObj }
}

// 是否是个人系统
export const isPersonal = () => getStorage('tenantId')?.startsWith('U')

// 根据code获取可用path
export const getAuthPathByCodes = authCodes => {
  const resSet = new Set(PublicPath)
  AuthConfig.forEach(item => {
    const findConfigItem = authCodes.includes(item.code)
    if (findConfigItem) {
      resSet.add(item.path)
    }
    // code 为空默认有权限
    if (!item.code) {
      resSet.add(item.path)
    }
  })
  // 如果是个人空间，需要添加默认路径 /cost/list
  if (isPersonal()) {
    PersonalPath.forEach(path => {
      resSet.add(path)
    })
  }
  return Array.from(resSet)
}
