export default {
  /**
   * 输入最长限制 <= max 默认 50
   */
  inputMaxLimit: (max = 50) => ({
    validator: (rule, value) => {
      if (value?.length > max) {
        return Promise.reject(new Error(`请勿超过${max}个字符`))
      }
      return Promise.resolve()
    },
  }),
  /**
   *
   * @param phoneNumber 手机号码
   * @returns 校验手机号码
   */
  validatePhoneNumber: () => ({
    validator: (rule, value) => {
      const phoneNumberRegex = /^1[3456789]\d{9}$/
      if (phoneNumberRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('请输入正确的手机号码'))
    },
  }),
}
