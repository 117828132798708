import { useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Layout } from 'antd'
import { useMount } from 'ahooks'
import { getUserInfo, getEnterprises } from '@/api'
import useGlobalStore from '@/store/useGlobalStore'
import LayHeader from '@/components/Header'
import LayoutMenu from '@/components/LayoutMenu'
import { getStorage } from '@/utils/tools'
import { DefaultHeaderKey, HeaderMenuEnum, HeaderPathMap, SliderWidth } from '@/config'
import ProtectedRoute from '@/components/Auth/ProtectedRoute'
import { useFetch } from '@/hooks/useFetch'
import { getHeaderMenuKeyByPath } from '@/utils/help'
import styles from './index.module.less'
import { getUnitAliasList, getUnitCollection, refreshSession } from '@/api/common/base'

const { Sider, Content, Footer } = Layout

const LayoutPage = () => {
  const {
    setGlobalInfo,
    tenantId,
    setUserInfo,
    setCurHeaderMenuKey,
    enterprisesList,
    menuKeysMap,
  } = useGlobalStore(s => ({
    setGlobalInfo: s.setGlobalInfo,
    tenantId: s.tenantId,
    setUserInfo: s.setUserInfo,
    setCurHeaderMenuKey: s.setCurHeaderMenuKey,
    enterprisesList: s.enterprisesList,
    menuKeysMap: s.menuKeysMap,
  }))

  const setState = useGlobalStore.setState
  const { collapsed, curEnterprise, curHeaderMenuKey, setCollapsed } = useGlobalStore(s => ({
    collapsed: s.collapsed,
    setCollapsed: s.setCollapsed,
    curEnterprise: s.curEnterprise,
    preRoute: s.preRoute,
    curHeaderMenuKey: s.curHeaderMenuKey,
  }))
  const location = useLocation()
  const { preRoute } = useGlobalStore.getState()
  const [layoutWidth, setLayoutWidth] = useState(0)
  const { selectEnterprise } = useFetch()
  const layoutRef = useRef(null)
  const storageTenantId = getStorage('tenantId') || ('' as string)
  // 浏览器前进后退
  const locationRef = useRef(location)
  locationRef.current = location

  useEffect(() => {
    window.onpopstate = () => {
      const headerMuneKey = getHeaderMenuKeyByPath(locationRef.current.pathname)
      setCurHeaderMenuKey(headerMuneKey)
      // console.log('location',location.pathname,'locationRef.current',locationRef.current.pathname, 'headerMuneKey', headerMuneKey)
    }

    // 设置定时器，每20分钟调用一次 API
    const intervalId = setInterval(refreshSession, 20 * 60 * 1000)

    // 获取单位转换关系集合
    getUnitCollection().then(res => {
      const res2 =
        Object.entries(res).reduce((cur, item) => {
          const [key, value] = item
          const [unit1, unit2] = key.split('_')
          return { ...cur, [unit2 + '_' + unit1]: 1 / value }
        }, {}) || {}
      setState({ unitConvertMap: { ...res, ...res2 } })
    })
    getUnitAliasList().then(res => {
      const resList = res?.result || []
      const aliasMap = {}
      resList.forEach(item => {
        aliasMap[item.standardUnit] = item.standardUnit
        const aliasArray = item.alias.split(item.aliasSeparator)
        aliasArray.forEach(alias => {
          aliasMap[alias] = item.standardUnit
        })
      })
      setState({ unitAliasMap: aliasMap })
    })
    // 清除定时器
    return () => {
      clearInterval(intervalId)
      window.onpopstate = null
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (layoutRef.current) {
        const clientWidth = layoutRef.current.clientWidth
        setLayoutWidth(clientWidth)
        // 如果宽度小于1300，就收起侧边栏
        if (window.innerWidth < 1300) {
          setCollapsed(true)
        }
      }
    }
    // 添加 resize 事件监听器
    window.addEventListener('resize', handleResize)
    // 初始设置
    handleResize()
    // 清除事件监听器
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const fetchData = async () => {
    let res = enterprisesList
    if (!enterprisesList.length) {
      res = await getEnterprises()
    }
    // 获取企业列表信息
    if (res?.length) {
      setGlobalInfo({
        enterprisesList: res,
      })
      if (preRoute === '/login') return
      const epids = res.map(item => String(item.epid))
      let epid = ''
      if (!storageTenantId) {
        // 没有本地默认第一个
        epid = epids[0]
      } else if (storageTenantId?.startsWith('E')) {
        epid = storageTenantId?.split(':')[1] // 有用之前的
        if (!epids.includes(epid)) {
          // 有但是不在记录里，还是默认第一个
          epid = epids[0]
        }
      } else {
        epid = null // 个人
      }
      selectEnterprise(epid, { isRefresh: true })
    }
  }

  // 页面刚进入和刷新的时候触发
  useMount(async () => {
    fetchData()
  })
  const containerClassName = ''

  const paddingLeft = useMemo(() => {
    if (curHeaderMenuKey === HeaderMenuEnum.zyzx) {
      return 0
    }
    return collapsed ? '80px' : SliderWidth
  }, [curHeaderMenuKey, collapsed])

  useEffect(() => {
    if (!tenantId) return
    const { pathname } = location
    // 根据当前路径获取头部菜单key
    // const findHeaderMenuKey = getHeaderMenuKeyByPath(menuKeysMap, pathname)
    const findHeaderMenuKey = getHeaderMenuKeyByPath(pathname)

    if (tenantId.startsWith('U')) {
      setCurHeaderMenuKey(HeaderMenuEnum.szzc)
    } else {
      setCurHeaderMenuKey(findHeaderMenuKey || DefaultHeaderKey)
      // NOTION: 个人企业暂时获取不到个人信息
      // 切换企业时，重新拉取用户信息
      getUserInfo().then(res => {
        if (res) {
          setUserInfo(res)
        }
      })
    }
  }, [tenantId])

  return (
    <Layout ref={layoutRef} className={`${styles.layout} ${containerClassName}`}>
      {/* 页头 */}
      <LayHeader layoutWidth={layoutWidth} />
      <ProtectedRoute style={{ paddingTop: '80px' }}>
        <Layout className={styles.siteLayout}>
          {/* 左侧菜单 */}
          <LayoutMenu />
          {/* 主要内容 */}
          <Content className={styles.content} style={{ paddingLeft }}>
            <div className={styles.headerEmpty} />
            <div className={styles.childrenContent}>
              {/* <div className={styles.breadWrap}><NavBreadcrumb /></div> */}
              {/* <Outlet /> */}
              <Outlet />
            </div>
          </Content>
        </Layout>
      </ProtectedRoute>
    </Layout>
  )
}

export default LayoutPage
