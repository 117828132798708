import useGlobalStore from '@/store/useGlobalStore'
import { AuthConfig, PublicPath } from '@/config/auth'
import { getStorage } from '@/utils/tools'
import { isPersonal } from '@/utils/help'

export function useAuth() {
  const { authCodes } = useGlobalStore()

  // 是否是个人系统

  const hasAuth = code => {
    if(isPersonal()) return true
    return authCodes.includes(code)
  }
  return { codes: authCodes, hasAuth }
}
