import { Button, Result } from 'antd'

function NoFind() {
  return (
    <Result
      status='404'
      title='404'
      subTitle='页面不存在'
      // extra={<Button type='primary'>返回首页</Button>}
    />
  )
}

export default NoFind
