import { Button } from 'antd'
import debounce from 'lodash-es/debounce'

interface DebounceButtonProps {
  className?: string
  children?: any
  delay?: number // 防抖 需要延迟的毫秒数
  onClick?

  [propName: string]: any
}
const DebounceButton = ({ children, onClick, delay = 300, ...rest }: DebounceButtonProps) => {
  return (
    <Button {...rest} onClick={debounce(onClick, delay)}>
      {children}
    </Button>
  )
}
export default DebounceButton
