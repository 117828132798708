// center pre和production 地址相同
const AdminUrlMap = {
  development: 'http://192.168.13.140:8182/enterprise/#/companyprofile/enterpriseInfo',
  qa: 'http://192.168.13.140:8182/enterprise/#/companyprofile/enterpriseInfo', // 测试
  pre: (window as any).adminUrl || "http://center.lubansoft.com/#/login", // 预发布环境
  production: (window as any).adminUrl || "http://center.lubansoft.com/#/login", // 线上
}

export function getCenterAdminUrl (){
  return AdminUrlMap[process.env.NODE_STAGE || 'development']
}
