import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

// 成本icon
/* eslint-disable */
const Svg = () => (
  // eslint-disable-next-line
  <svg
    className='icon'
    viewBox='0 0 1024 1024'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    p-id='4366'
    width='19'
    height='19'
  >
    <path
      d='M784.83 527.35a25 25 0 0 0-35.36 0l-78.34 78.34-78.34-78.34a25 25 0 0 0-35.36 35.36l53.39 53.39h-35.6a25 25 0 0 0 0 50h70.93v45.13h-70.92a25 25 0 0 0 0 50h70.93v71.54a25 25 0 0 0 50 0v-71.54h70.93a25 25 0 0 0 0-50h-70.93v-45.14h70.93a25 25 0 0 0 0-50h-35.65l53.39-53.39a25 25 0 0 0 0-35.35z'
      fill='currentColor'
      p-id='4367'
    ></path>
    <path
      d='M863 65.16H161a64.19 64.19 0 0 0-64 64v767.68a64.19 64.19 0 0 0 64 64h702a64.19 64.19 0 0 0 64-64V129.16a64.19 64.19 0 0 0-64-64z m0 831.58l-0.11 0.11H161.12l-0.11-0.11V129.27l0.11-0.11h701.76l0.11 0.11z'
      fill='currentColor'
      p-id='4368'
    ></path>
    <path
      d='M319.06 349.28h383.27a32 32 0 0 0 0-64H319.06a32 32 0 0 0 0 64zM319.06 477.11h383.27a32 32 0 0 0 0-64H319.06a32 32 0 0 0 0 64zM447.09 542.32h-128a32 32 0 0 0 0 64h128a32 32 0 1 0 0-64z'
      fill='currentColor'
      p-id='4369'
    ></path>
  </svg>
)

const IconComponent = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Svg} {...props} />
)

export default IconComponent
