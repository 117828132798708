import qs from 'qs'
import { Dropdown, Avatar, message } from 'antd'
import { useMemo } from 'react'
import type { MenuProps } from 'antd'
import {
  CaretDownOutlined,
  LogoutOutlined,
  InsertRowRightOutlined,
  UserOutlined,
  CheckOutlined,
  CloudOutlined,
} from '@ant-design/icons'
import useGlobalStore from '@/store/useGlobalStore'
import { useFetch } from '@/hooks/useFetch'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserInfo, loginOut } from '@/api'
import { getStorage, removeStorage, setStorage } from '@/utils/tools'
import { ReactComponent as AvatarUserIcon } from '@/assets/avatar-user.svg'
import { HeaderMenuEnum } from '@/config'
import styles from './index.module.less'
import { getCenterAdminUrl } from '@/utils/env'

const AvatarDropdown = () => {
  const currentTenantId = useGlobalStore(s => s.tenantId)
  const currentUser = useGlobalStore(s => s.userInfo)
  const enterprisesList = useGlobalStore(s => s.enterprisesList)
  const resetGlobalStore = useGlobalStore(s => s.resetGlobalStore)
  const { changeEnterprise } = useFetch()
  const navigate = useNavigate()
  const location = useLocation()
  const tenantId = getStorage('tenantId') || ''

  const handleSelectEnterprise = async (key: any) => {
    // 没有改变直接 return
    if (key === currentTenantId) return
    let epid = key
    if (key === 'personal') {
      epid = null
    }
    changeEnterprise(epid)
  }

  const handleLoginOut = async () => {
    const { pathname, search } = location
    // 记录当前路由，登入后重新进去
    const redirect = `${pathname}${search}`
    const params = qs.stringify({ redirect })
    loginOut().then(() => {
      navigate(`/login?${params}`)
      message.success('已退出登录')
      removeStorage('token')
      // removeStorage('epid')
      // removeStorage('tenantId')
      removeStorage('username')
      resetGlobalStore()
    })
  }
  const currentUserName = currentUser?.userName || getStorage('userName') || '--'

  const onMenuClick: MenuProps['onClick'] = event => {
    const { key } = event
    if (key === 'logout') {
      handleLoginOut()
      return
    }

    if (key === 'admin') {
      const adminUrl =getCenterAdminUrl()
      window.open(adminUrl, '_blank')
      return
    }

    handleSelectEnterprise(key as any)
  }

  const selectedKeys = useMemo(() => {
    if (tenantId?.startsWith('U')) {
      return ['personal']
    }
    const key = tenantId.split(':')[1]
    return [key]
  }, [tenantId])

  const enterprisesOptions = useMemo(
    () =>
      enterprisesList.map(i => ({
        key: i.epid,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }} className={styles.labelWrap}>
            {i.enterpriseName}
            {selectedKeys.includes(`${i.epid}`) ? (
              <CheckOutlined className={styles.checkIcon} />
            ) : null}
          </div>
        ),
        icon: <InsertRowRightOutlined />,
      })),
    [enterprisesList, selectedKeys],
  )

  const menuItems = [
    {
      key: 'center',
      icon: <UserOutlined />,
      label: '用户空间',
      children: [
        {
          key: 'personal',
          icon: <UserOutlined />,
          // 个人账号姓名较特殊，需要单独处理
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }} className={styles.labelWrap}>
              {currentUserName}
              {selectedKeys.includes(`personal`) ? (
                <CheckOutlined className={styles.checkIcon} />
              ) : null}
            </div>
          ),
        },
        ...enterprisesOptions,
      ],
    },
    // {
    //   key: 'settings',
    //   icon: <SettingOutlined />,
    //   label: '个人设置',
    // },
    // {
    //   type: 'divider' as const,
    // },
    {
      key: 'admin',
      icon: <CloudOutlined />,
      label: '后台管理',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: '退出登录',
    },
  ]

  return (
    <Dropdown
      overlayClassName={styles.container}
      menu={{
        selectedKeys,
        onClick: onMenuClick,
        items: menuItems,
      }}
    >
      <span className={styles.actionWrap}>
        <Avatar size='small' icon={<AvatarUserIcon />} style={{ backgroundColor: '#fff' }} />
        <span className={styles.username}>{currentUserName}</span>
        <CaretDownOutlined />
      </span>
    </Dropdown>
  )
}

export default AvatarDropdown
