import MenuSzgztIcon from '@/components/icon/menuSzgzt'
import MenuQyzbkIcon from '@/components/icon/menuQyzbk'
import MenuSjzdIcon from '@/components/icon/menuSjzd'
import MenuJcqddeIcon from '@/components/icon/menujcqdde'
import MenuCostIcon from '@/components/icon/menuCost'
import { HeaderMenuEnum } from '@/config'

// 数字资产菜单
const szzcMenuList = [
  {
    title: '数字工作台',
    key: 'indicator',
    icon: <MenuSzgztIcon style={{ fontSize: '32px' }} />,
    children: [
      {
        path: '/indicator/list',
        title: '指标项目列表',
        key: '/indicator/list',
      },
      {
        path: '/indicator/reviewList',
        title: '待审核指标列表',
        key: '/indicator/reviewList',
      },
    ],
  },
  {
    title: '企业指标库',
    key: 'enterprise',
    icon: <MenuQyzbkIcon />,
    children: [
      {
        path: '/enterprise/project',
        title: '整体工程指标',
        key: '/enterprise/project',
      },
      {
        path: '/enterprise/single',
        title: '单项工程指标',
        key: '/enterprise/single',
      },
      {
        path: '/enterprise/unit',
        title: '单位工程指标',
        key: '/enterprise/unit',
      },
      {
        path: '/enterprise/inventory',
        title: '清单综合单价',
        key: '/enterprise/inventory',
      },
      {
        path: '/enterprise/material',
        title: '工料价格指标',
        key: '/enterprise/material',
      },
    ],
  },
  {
    title: '数据字典',
    icon: <MenuSjzdIcon />,
    key: 'dict',
    children: [
      {
        path: '/dict/professional',
        title: '专业信息',
        key: '/dict/professional',
      },
      // {
      //   path: '/dict/total',
      //   title: '总体专业',
      //   key: '/dict/total',
      // },
      // {
      //   path: '/dict/project',
      //   title: '工程专业',
      //   key: '/dict/project',
      // },
      {
        path: '/dict/sort',
        title: '工程分类',
        key: '/dict/sort',
      },
      {
        path: '/dict/info',
        title: '项目信息',
        key: '/dict/info',
      },
      {
        path: '/dict/feature',
        title: '工程特征',
        key: '/dict/feature',
      },
      // {
      //   path: '/dict/type',
      //   title: '造价类型',
      //   key: '/dict/type',
      // },
      {
        path: '/dict/template',
        title: '企业指标模板',
        key: '/dict/template',
      },
      {
        path: '/official-template/list',
        title: '政府指标模板',
        key: '/official-template/list',
      },
    ],
  },
  {
    title: '基础清单定额库',
    icon: <MenuJcqddeIcon />,
    key: 'inventory',
    children: [
      {
        path: '/inventory/list',
        title: '清单数据',
        key: '/inventory/list',
      },
      {
        path: '/inventory/ration',
        title: '定额数据',
        key: '/inventory/ration',
      },
      {
        path: '/inventory/laborMaterialsMachinery',
        title: '人材机数据',
        key: '/inventory/laborMaterialsMachinery',
      },
    ],
  },
  {
    title: '成本方案',
    icon: <MenuCostIcon />,
    key: 'cost',
    children: [
      {
        path: '/cost/list',
        title: '成本科目库',
        key: '/cost/list',
      },
      {
        path: '/cost/split',
        title: '自动拆分方案',
        key: '/cost/split',
      },
    ],
  },
]

// 工作中心菜单
const workbenchMenuList = [
  // {
  //   title: '项目登记',
  //   icon: <MenuSzgztIcon style={{ fontSize: '32px' }} />,
  //   key: 'cloud-project/list',
  //   path: '/cloud-project/list',
  // },
  // {
  //   title: '业绩库',
  //   key: 'performance',
  //   icon: <ApartmentOutlined />,
  //   children: [
  //     {
  //       path: '/performance/list',
  //       title: '公司业绩库',
  //       key: 'performance/list',
  //     },
  //   ],
  // },
]

// 基础数据菜单
const baseDataMenuList = [
  {
    title: '基础配置',
    key: 'template',
    icon: <MenuJcqddeIcon />,
    children: [
      {
        path: '/template/list',
        title: '项目模板',
        key: '/template/list',
      },
      {
        path: '/field/list',
        title: '字段设置',
        key: '/field/list',
      },
    ],
  },
]

export const menuKeysMap = {
  [HeaderMenuEnum.szzc]: szzcMenuList, // 数字资产
  [HeaderMenuEnum.zyzx]: workbenchMenuList, // 作业中心
  [HeaderMenuEnum.jcsj]: baseDataMenuList, // 基础数据
}
