import axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { message } from 'antd'
import { isNumber } from 'lodash-es'

import qs from 'qs'
import { getStorage } from './tools'
import { loginAgainConfirm } from './help'

export interface ResponseData<T> {
  data: T
  code?: number
  msg?: string
  success?: boolean
  message?: string
}
// http://192.168.13.140:8182/gateway
// 打包时根据自定义的环境变量 NODE_STAGE 选择不同的api
const ConfigApi = {
  // development: 'https://zjy.lubansoft.com/gateway',
  development: 'http://192.168.13.141:83/gateway',
  qa: 'http://192.168.13.141:83/gateway', // 测试

  // development: 'http://222.71.50.180:8185/gateway',
  // qa: 'http://222.71.50.180:8185/gateway', // 测试

  // 222.71.50.180:8185/gateway

  // development: 'https://zjy.lubansoft.com/gateway',
  // development: 'http://192.168.13.141:83/gateway-147',
  // qa: 'http://192.168.13.141:83/gateway-147',

  pre: window.BASE_URL || 'http://zjy-pre.lubansoft.com/gateway', // 预发布环境
  production: window.BASE_URL || 'https://zjy.lubansoft.com/gateway', // 线上
} as Record<string, string>

// window.addEventListener('unhandledrejection', event => {
//   event.preventDefault()
//   // 这个事件对象有两个特殊的属性：
//   // alert(event.promise); // [object Promise] —— 生成该全局 error 的 promise
//   // alert(event.reason); // Error: Whoops! —— 未处理的 error 对象
//   console.log(event.promise, event.reason)
// })

/**
 * 重组URL
 * @param path
 * @returns {string}
 */
export function getUrl(path: string): string {
  return `${ConfigApi[process.env.NODE_STAGE || 'development']}${path}`
}

// 指定 axios 请求主体类型
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 是否在跨域请求中发送凭据，如cookies或授权头
axios.defaults.withCredentials = true

// 添加请求拦截器
axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 获取用户token，用于校验
    if (!config.url?.includes('/login')) {
      const token = getStorage('token')
      if (token) {
        config.headers['access-token'] = token
      }
    }
    return config
  },
  (error: AxiosError) => Promise.reject(error),
)

// 添加响应拦截器，拦截登录过期或者没有权限
axios.interceptors.response.use(
  (response: AxiosResponse<ResponseData<any>>) => {
    if (!response?.data?.code) {
      return response
    }

    const { code, msg } = response.data

    // 登录已过期，token失效
    if (code === 401 || code === 1002 || code === 1007 || code === 1021 || code === 1013) {
      loginAgainConfirm() // 重定向登录界面
      return Promise.reject(response)
    }
    // 错误码
    const errCodes = [
      500, 1007, 40000, -119, 0, 50000, 1006, 1035, 1037, 1021, 400, -102, 1003, 1014, 1015, 1016
    ]
    if (isNumber(code) && errCodes.includes(code)) {
      message.error(response.data.msg || response.data.message)
      return Promise.reject(response)
    }
    return response
  },
  (error: AxiosError<ResponseData<any>>) => {
    console.log('error :>> ', error)
    try {
      const { response } = error
      const msgInfo = response?.data?.msg || response?.data?.message || '服务错误'
      // 处理不同网络状态码下的错误
      if (response?.status === 401) {
        // message.error('没有当前功能权限，请联系管理员')
        loginAgainConfirm() // 重定向登录界面
        return Promise.reject(response)
      }
      // 无response，有可能是接口跨域了
      if (!response) {
        // key避免多次提示
        message.error({ content: '服务错误', key: 'response-error' })
        return Promise.reject('服务错误')
      }
      if (response.status === 403) {
        message.error('没有当前功能权限，请联系管理员')
        return Promise.reject(response)
      }
      if (response.status === 400) {
        message.error(msgInfo)
        return Promise.reject(response)
      }
      if (response.status === 504) {
        message.error('服务器超时，请稍后再试')
        return Promise.reject(response)
      }
      return Promise.reject(response)
    } catch (e) {
      console.log(e)
    }
    return error
  },
)

interface ParamT {
  [name: string]: any
}

export async function request<T>(
  path: string,
  params?: ParamT | FormData,
  options?: AxiosRequestConfig,
): Promise<T> {
  const url = getUrl(path)
  const opts = {
    ...options,
    url,
    data: params,
  }
  // get, delete请求参数处理
  if (options.method.toLowerCase() === 'get' || options.method.toLowerCase() === 'delete') {
    const query = params ? `?${qs.stringify(params)}` : ''
    opts.url = `${url}${query}`
  }
  const axiosResult = await axios.request(opts)
  return axiosResult?.data?.data
}

export function get<T>(path: string, params?: ParamT, config?: AxiosRequestConfig) {
  return request<T>(path, params, {
    method: 'GET',
    ...config,
  })
}

export function post<T>(path: string, params?: ParamT, config?: AxiosRequestConfig) {
  return request<T>(path, params, {
    method: 'POST',
    ...config,
  })
}

export function patch<T>(path: string, params?: ParamT, config?: AxiosRequestConfig) {
  return request<T>(path, params, {
    method: 'PATCH',
    ...config,
  })
}

export function put<T>(path: string, params?: ParamT, config?: AxiosRequestConfig) {
  return request<T>(path, params, {
    method: 'PUT',
    ...config,
  })
}

export function remove<T>(path: string, params?: ParamT, config?: AxiosRequestConfig) {
  return request<T>(path, params, {
    method: 'DELETE',
    ...config,
  })
}

export default request
