import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Form, Input, Space, Spin, Table } from 'antd'
import { LockOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import qs from 'qs'
import clsx from 'clsx'
import md5 from 'js-md5'
import debounce from 'lodash-es/debounce'
import { getStorage, removeStorage, setStorage } from '@/utils/tools'
import { getEnterprises, login } from '@/api'
import DebounceButton from '@/components/DebounceButton'
import Footer from '@/components/Footer'
import { ReactComponent as LubanLogo } from '@/assets/luban-logo.svg'
import { ReactComponent as HeaderLogoIconBG } from '@/assets/zjylogoBG.svg'
import LinkText from '@/components/LinkText'
import { useMount, useSetState, useUnmount } from 'ahooks'
import useGlobalStore from '@/store/useGlobalStore'
import { useFetch } from '@/hooks/useFetch'
import ApplyModal from './components'
import styles from './index.module.less'

function Login() {
  const { setGlobalInfo, token } = useGlobalStore(s => ({
    token: s.token,
    setGlobalInfo: s.setGlobalInfo,
  }))
  const setGlobalState = useGlobalStore.setState
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  const { selectEnterprise } = useFetch()

  const [state, setState] = useSetState({
    enterpriseList: [] as any,
    dataSource: [],
    searchValue: '',
    selectedRowKey: '' as any,
    isShowSelect: false,
    isApplyOpen: false,
    loading: false,
    loginImageLoaded: false, // 登录背景图是否加载完成
  })
  const {
    enterpriseList,
    dataSource,
    isShowSelect,
    searchValue,
    selectedRowKey,
    isApplyOpen,
    loading,
  } = state

  const columns = [
    {
      title: '序号',
      dataIndex: 'number',
      width: 160,
      render: (text, record, index) => {
        return <span>{index + 1}</span>
      },
    },
    {
      title: '企业名称',
      dataIndex: 'enterpriseName',
      width: 390,
    },
  ]

  const selectRow = record => {
    const key = record.epid
    setState({
      selectedRowKey: key,
    })
  }

  const handleSubmit = debounce(async values => {
    setState({
      loading: true,
    })
    const { username, password } = values
    // const localTenantId = getStorage('tenantId')
    try {
      const {
        token: tokenStr,
        tenantId: serverTenantId,
        username: usernameStr,
      } = await login({
        username,
        password: md5(password),
        loginType: '630',
      })
      // const serverTenantId = null
      if (tokenStr) {
        setStorage('token', tokenStr)
        setStorage('userName', usernameStr)
        setGlobalInfo({
          tenantId: serverTenantId,
          token: tokenStr,
          preRoute: '/login',
          userInfo: {
            userName: usernameStr,
          },
        })
        setGlobalState({
          lastLoginTenantId: serverTenantId,
        })

        let resList = await getEnterprises()
        if (resList?.length) {
          setGlobalInfo({
            enterprisesList: resList,
          })
          const epids = resList.map(item => String(item.epid))
          let epid = null // 需要进入的企业的id
          if (epids.length > 0) {
            if (serverTenantId) {
              if (serverTenantId?.startsWith('E')) {
                // 上次记录的企业id
                epid = serverTenantId?.split(':')[1]
              }
            } else {
              // 初次登录会进到这里
              epid = epids[0] // 默认进入第一个企业
            }
          }

          // const epid = tenantId?.startsWith('E') ? tenantId?.split(':')[1] : null
          selectEnterprise(epid)
        } else {
          selectEnterprise(null)
        }
      }
      setState({
        loading: false,
      })
    } catch (error) {
      setState({
        loading: false,
      })
    }
  }, 300)

  // 本地搜索
  const fuzzySearch = () => {
    if (searchValue) {
      const results = enterpriseList.filter(value => {
        return value?.enterpriseName.toLowerCase().includes(searchValue)
      })
      setState({
        dataSource: results,
      })
    } else {
      setState({
        enterpriseList,
      })
    }
    setState({
      selectedRowKey: '',
    })
  }

  const handleRegister = () => {
    let REGISTER_URL = ''
    if (process.env.NODE_STAGE !== 'production') {
      REGISTER_URL = 'http://192.168.13.40:8084/ac/luban-pass/html/regist.html'
    } else {
      REGISTER_URL = 'http://passport.luban.cn/luban-pass/html/regist.html'
    }
    window.open(REGISTER_URL)
  }

  const handleFindPassword = () => {
    let FIND_PASSWORD_URL = ''
    if (process.env.NODE_STAGE !== 'production') {
      FIND_PASSWORD_URL = 'http://192.168.13.40:8084/ac/luban-pass/html/phone-find-pass.html'
    } else {
      FIND_PASSWORD_URL = 'http://passport.luban.cn/luban-pass/html/phone-find-pass.html'
    }
    window.open(FIND_PASSWORD_URL)
  }

  const handleApply = () => {
    setState({
      isApplyOpen: true,
    })
  }

  const handleModalCancel = () => {
    setState({
      isApplyOpen: false,
    })
  }

  // 是否展示注册，忘记密码等信息
  const visibleRegister = !!(window as any).isEnterpriseDeployment

  useMount(() => {
    // 首页加载图片过大，采用懒加载
    const img = new Image()
    img.src = `${process.env.PUBLIC_URL}/img/loginBg.png`
    img.onload = () => {
      console.log('img loaded')
      setState({
        loginImageLoaded: true,
      })
    }
  })

  const bgStyle: React.CSSProperties = {
    background: state.loginImageLoaded
      ? `no-repeat center/cover url(${process.env.PUBLIC_URL}/img/loginBg.png)`
      : '#dde5f0',
  }

  return (
    <div className={styles['login-container']}>
      <div className={styles['login-bg']}  style={bgStyle}/>
      <div className={styles['luban-logo']}>
        <LubanLogo />
      </div>
      {!isShowSelect ? (
        <div className={styles['login-right']}>
          <Spin spinning={loading}>
            <div className={styles['login-logo']}>
              <HeaderLogoIconBG />
              <span>造价云</span>
            </div>
            <div className={styles['login-sub-title']}>欢迎使用造价云</div>
            <div className={styles['login-title']}>登录您的账户</div>
            <Form
              form={form}
              className={styles['login-form']}
              name='normal_login'
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
            >
              <Form.Item name='username' rules={[{ required: true, message: '请输入账号' }]}>
                <Input size='large' prefix={<UserOutlined />} placeholder='请输入账号' />
              </Form.Item>
              <Form.Item name='password' rules={[{ required: true, message: '请输入密码' }]}>
                <Input
                  size='large'
                  prefix={<LockOutlined />}
                  type='password'
                  placeholder='请输入密码'
                />
              </Form.Item>
              <Form.Item>
                <Button
                  size='large'
                  type='primary'
                  htmlType='submit'
                  className={styles['login-form-button']}
                >
                  登录
                </Button>
              </Form.Item>
              <div className={clsx({ hidden: visibleRegister }, styles['login-tool'])}>
                <div>
                  <LinkText onClick={handleRegister}>注册用户</LinkText>
                  <LinkText onClick={handleApply} className='ml-15'>
                    申请试用
                  </LinkText>
                </div>
                <span onClick={handleFindPassword} className='text-link'>
                  忘记密码
                </span>
              </div>
            </Form>
            <Footer />
          </Spin>
        </div>
      ) : (
        <div className={styles['login-select']}>
          <h1 className={styles.title}>选择企业</h1>
          <Input
            placeholder='请输入你需要搜索的企业'
            suffix={<SearchOutlined onClick={fuzzySearch} />}
            onPressEnter={fuzzySearch}
            className='keywords-input'
            style={{ marginBottom: '20px' }}
            onChange={e => {
              setState({
                searchValue: e.target.value.toLowerCase()?.trim(),
              })
            }}
          />
          <Table
            rowKey='epid'
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onRow={record => ({
              onClick: () => {
                selectRow(record)
              },
            })}
            rowSelection={{
              type: 'radio',
              onChange: (newSelectedRowKeys: React.Key[]) => {
                setState({
                  selectedRowKey: newSelectedRowKeys[0] as string,
                })
              },
              selectedRowKeys: [selectedRowKey],
            }}
          />
          <div className={styles['select-btn']}>
            <Button
              key='cancel'
              onClick={() => {
                setState({
                  isShowSelect: false,
                  selectedRowKey: '',
                })
              }}
            >
              取消
            </Button>
            <DebounceButton
              key='ok'
              type='primary'
              disabled={!(selectedRowKey || selectedRowKey === 0)}
              // onClick={() => handleSelectEnterprise(selectedRowKey)}
            >
              确认
            </DebounceButton>
          </div>
          <Footer />
        </div>
      )}
      <ApplyModal open={isApplyOpen} onCancel={handleModalCancel} />
    </div>
  )
}

export default Login
