import dayjs from 'dayjs'
import { create } from 'zustand'

type ProjectFieldType = {
  candidates: any[]
  fieldName: string
  fieldTitle: string
  fieldType: string
}
interface State {
  orgSearchValue: string
  orgTreeData: any[]
  orgTreeSourceData: any[]
  orgSelectKeys?: any[]
  orgSelectNode?: any
  authCodes?: any[] // 云工程权限码
  // 请求权限是否完成
  authed?: boolean
  // 搜索
  templateOptions: any[]
  newProjectTemplateOptions: any[]
  projectSearchKeyword: string
  searchFormData: any  // 搜索表单数据，包含关键字，归属状态和过滤条件等

  // 表格
  tableSourceData: any[]
  tableShowData: any[]
  tableParams: any

  // 云工程列表
  // projectTableParams: any

  // 列设置
  columnSettingArray: any // 列设置数组
  columnSettingSelectKeys: any[] // 列选中的key

  createProjectOpen: boolean
  columnsSettingOpen: boolean
  projectArchiveOpen: boolean
  projectDetailOpen: boolean

  curSelectProjectItem: any
  spaceData?: any

  // 地区选项
  allLocationList: any[] // 所有地区列表
  locationOptions: any[]
  // 业态选项
  allYetaiList: any[] // 所有业态用来工程列表回显
  yetaiOptions: any[]

  // 所有字典值
  allDictData: any[]
  collapsed: boolean

  // 所有项目字段值。包括字段信息和筛选项，列配置信息里没有下拉选项
  allProjectFields: any[]

  // 全局搜索开关
  globalSearchOpen: boolean

  // 导出开关
  exportOpen: boolean

  /** 地图相关数据 */

  // 地图省份
  provinceName: string
  provinceCode: string
  monthValue: any
  chartYetaiList: any[]
  chartData: any
  chartAmountData: any // 金额数据，项目总投资等等
  chartMapDescData: any // 地图描述数据
}

interface Actions {
  setOrgTreeDatasetOrgTreeData: (value: State['orgTreeData']) => void
  setSearchValue: (value: State['orgSearchValue']) => void
  resetStore: () => void
  setAuthCodes: (value: State['authCodes']) => void
  // 获取格式化的月份数组
  getMonthValue: () => string[]
  // 获取请求参数
  getEchartQueryParams: () => any
}

// define the initial state
const initialState: State = {
  projectSearchKeyword: '',
  allProjectFields: [],
  authCodes: [],
  authed: false,
  allYetaiList: [],
  allLocationList: [],
  locationOptions: [],
  yetaiOptions: [],
  curSelectProjectItem: null,
  projectArchiveOpen: false,
  tableParams: {
    tenantId: '',
    searchKey: '',
    templateId: '',
    ascription: '',
    isArchived: '',
  },
  createProjectOpen: false,
  columnsSettingOpen: false,
  projectDetailOpen: false,
  orgSearchValue: '',
  orgTreeData: [],
  orgTreeSourceData: [],
  templateOptions: [],
  newProjectTemplateOptions: [],
  tableShowData: [],
  tableSourceData: [],
  columnSettingArray: [],
  columnSettingSelectKeys: [], // 默认选中字段:项目名称。
  allDictData: [],
  spaceData: {
    space: '', // 所有空间
    used: '', // 已用空间
  },
  orgSelectKeys: [],
  orgSelectNode: null,
  collapsed: false,
  globalSearchOpen: false,
  exportOpen: false,
  provinceName: '',
  provinceCode: '',
  monthValue: [dayjs().startOf('year'), dayjs().endOf('year')],
  chartYetaiList: [],
  chartData: {
    htResults: [], // 合同
    fwResults: [], // 服务
    yhtResults: [], // 月合同
    dqResults: [], // 地区
    ytResults: [], // 业态
    mapResults: [], // 项目分布
  },
  searchFormData: {},
  chartAmountData: [],
  chartMapDescData: [],
}

const useStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setAuthCodes: v => set({ authCodes: v }),
  setOrgTreeDatasetOrgTreeData: v => set({ orgTreeData: v }),
  setSearchValue: v => set({ orgSearchValue: v }),
  resetStore: () => set(initialState),
  getMonthValue: () => {
    const { monthValue } = get()
    const startMills = monthValue
      ? dayjs(monthValue[0]).startOf('month').format('YYYY-MM-DD')
      : undefined
    const endMills = monthValue
      ? dayjs(monthValue[1]).endOf('month').format('YYYY-MM-DD')
      : undefined
    return [startMills, endMills]
  },
  getEchartQueryParams: () => {
    const { orgSelectKeys, provinceCode } = get()
    const [startMills, endMills] = get().getMonthValue()
    return {
      startDate: startMills,
      endDate: endMills,
      orgId: orgSelectKeys?.at(0),
      province: provinceCode,
    }
  },
}))

export default useStore
