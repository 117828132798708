import { ConfigProvider, Layout, Menu, MenuProps } from 'antd'
import { useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useGlobalStore from '@/store/useGlobalStore'
import IoncSysCollapse from '@/components/icon/sysCollapse'
import IoncSysExpand from '@/components/icon/sysExpand'
import { DefaultOpenKeys, HeaderPathMap, SelectedKeysMap, SliderWidth } from '@/config'
import { getDefaultOpenKeys } from '@/utils/help'
import { getStorage } from '@/utils/tools'
import styles from './index.module.less'
import { personMenuList } from './constant'

type MenuItem = Required<MenuProps>['items'][number]
const { Sider, Content } = Layout

const LayoutMenu = () => {
  const [menuData, setMenuData] = useState<MenuItem[]>([])
  const { pathname } = useLocation()
  const {
    collapsed,
    curHeaderMenuKey,
    tenantId: globalTenantId,
    menuKeysMap,
    setCollapsed,
  } = useGlobalStore(s => ({
    collapsed: s.collapsed,
    curHeaderMenuKey: s.curHeaderMenuKey,
    tenantId: s.tenantId,
    menuKeysMap: s.menuKeysMap,
    setCollapsed: s.setCollapsed,
  }))

  const [openKeys, setOpenKeys] = useState([])
  const tenantId = getStorage('tenantId') || ''
  const curMenulist = menuKeysMap[curHeaderMenuKey] || []
  const rootSubmenuKeys = curMenulist?.map(item => item.key) || []

  // 当前路由菜单高亮
  const selectedLeftMenuKey = SelectedKeysMap[`${pathname}`] || '' // 默认每个路由都有对应key值

  // 根据左侧菜单key值获取顶部菜单key值
  // const getHeaderMenuKey = leftMenuKey => {
  //   for (const key in menuKeysMap) {
  //     const menuKeys = menuKeysMap[key]
  //     if (menuKeys.some(item => item.key.includes(leftMenuKey))) {
  //       return key
  //     }
  //   }
  //   return ''
  // }

  // 处理后端 menu data
  const transformData = (data: any[]): MenuItem[] =>
    data.map(item => {
      if (item.children) {
        return {
          popupClassName: styles.popupMenu,
          key: item.key,
          icon: item.icon,
          label: item.title,
          children: transformData(item.children),
        }
      }
      return {
        key: item.key,
        icon: item.icon,
        label: <Link to={`${item.key}`}>{item.title}</Link>,
      }
    })

  useEffect(() => {
    if (!selectedLeftMenuKey) return
    if (menuData.length) {
      // 刷新页面时获取当前子菜单的父菜单key值
      const urlOpenKeys = getDefaultOpenKeys(menuData, selectedLeftMenuKey)
      setOpenKeys(urlOpenKeys)
    }
  }, [menuData, selectedLeftMenuKey])

  useEffect(() => {
    if (!tenantId) return
    let listData = curMenulist
    if (tenantId.startsWith('U')) {
      listData = personMenuList
    }
    setMenuData(transformData(listData))
  }, [tenantId, curMenulist])

  // 只展开当前父级菜单,点击菜单，收起其他展开的所有菜单，保持菜单聚焦简洁。
  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  // 是否是作业中心
  const isZyzxMenu = curHeaderMenuKey === 'zyzx'

  // 宽度
  const siderWidth = isZyzxMenu ? 0 : SliderWidth

  // slider style
  const siderStyle: any = useMemo(() => {
    if (isZyzxMenu) {
      // 作业中心隐藏菜单
      return { position: 'fixed', minWidth: 0 }
    }
    return {
      position: ' fixed',
    }
  }, [curHeaderMenuKey])

  const sliderHiddenClassName = isZyzxMenu ? styles['sliderHidden'] : ''
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            borderRadius: 0,
            radiusSubMenuItem: 0,
            radiusItem: 0,
          },
        },
      }}
    >
      <div className={styles.container}>
        <Sider
          theme='light'
          style={siderStyle}
          className={clsx(styles.sider, sliderHiddenClassName)}
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={siderWidth}
        >
          <div style={{ flex: '1 1 0%', overflow: 'hidden auto' }}>
            <Menu
              mode='inline'
              onOpenChange={onOpenChange}
              defaultOpenKeys={[DefaultOpenKeys]}
              openKeys={openKeys}
              items={menuData}
              selectedKeys={[selectedLeftMenuKey]}
              triggerSubMenuAction='click'
            />
          </div>
          {/* 收起按钮 */}
          <div
            onClick={() => setCollapsed(!collapsed)}
            className={clsx(styles.sliderTrigger, { [styles.btnCollapsed]: collapsed })}
          >
            {collapsed ? <IoncSysExpand /> : <IoncSysCollapse />}
          </div>
        </Sider>
      </div>
    </ConfigProvider>
  )
}

export default LayoutMenu
