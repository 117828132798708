import styles from './index.module.less'

const Footer = () => {
  return (
    <div className={styles['page-footer']}>
      沪ICP备09024855号-19 | ©1999-2023 鲁班软件股份有限公司版权所有
    </div>
  )
}

export default Footer
