import { get, post } from "@/utils/request"

// 转拼音
export const getCnPy = (data: any) =>
  post<any>('/cost-cloud-support/common/cn_2_py', data)

export const refreshSession = (data: any) =>
  get<any>('/cost-cloud-support/common/keepalive', data)

// 通用表达式计算接口
export const calcByServer = (data: any) => post<any>('/cost-cloud-support/common/calc', data)

// 单位转换
export const getUnitCollection = () => get<Record<string, number>>('/cost-cloud-indicator/unit_convert/map')

// 单位别名接口
export const getUnitAliasList = () => get<ListResT<any>>('/cost-cloud-indicator/unit_alias/page')
