import React, { useMemo } from 'react'
import { Layout, Tabs, theme } from 'antd'
import type { TabsProps } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import useGlobalStore from '@/store/useGlobalStore'
import AvatarDropdown from '@/components/RightContent/AvatarDropdown'
import { HeaderMenuEnum, SliderWidth } from '@/config'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as HeaderLogoIcon } from '@/assets/zjylogo.svg'
import { getStorage } from '@/utils/tools'
import styles from './index.module.less'
import clsx from 'clsx'

const { Header } = Layout

interface Props {
  layoutWidth: number
}

const LayHeader = ({ layoutWidth }: Props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const navigate = useNavigate()
  const { collapsed, setCollapsed, curHeaderMenuKey, setCurHeaderMenuKey, menuKeysMap } =
    useGlobalStore(s => ({
      collapsed: s.collapsed,
      setCollapsed: s.setCollapsed,
      curHeaderMenuKey: s.curHeaderMenuKey,
      setCurHeaderMenuKey: s.setCurHeaderMenuKey,
      menuKeysMap: s.menuKeysMap,
    }))
  const tenantId = getStorage('tenantId')
  // 隐藏菜单缩放icon
  const hideSliderIcon = curHeaderMenuKey === HeaderMenuEnum.zyzx

  const defaultItems: TabsProps['items'] = [
    {
      key: HeaderMenuEnum.zyzx,
      label: '作业中心',
    },
    {
      key: HeaderMenuEnum.szzc,
      label: '数字资产',
    },
    {
      key: HeaderMenuEnum.jcsj,
      label: '基础数据',
    },
  ]

  const items: TabsProps['items'] = useMemo(() => {
    if (tenantId?.startsWith('U')) {
      const personItems = defaultItems.filter(i => i.key === HeaderMenuEnum.szzc)
      return personItems
    }
    return defaultItems
  }, [tenantId])

  // 获取当前顶部菜单的第一个路由
  const getFirstRoute = (key) => {
    const menuList = menuKeysMap[key]
    if (menuList) {
      const firstRoute = menuList[0]?.children?.[0]?.path || '/404'
      return firstRoute
    }
    return '/404' // 没有返回404路由
  }

  const handTabsChange = key => {
    setCurHeaderMenuKey(key)

    // 加载对应路由
    if (key === HeaderMenuEnum.zyzx) {
      navigate('/cloud-project/list')
    } else if (key === HeaderMenuEnum.szzc) {
      // navigate('/indicator/list' )
      const path = getFirstRoute(HeaderMenuEnum.szzc)
      navigate(path)
    } else if (key === HeaderMenuEnum.jcsj) {
      // navigate('/template/list')
      navigate(getFirstRoute(HeaderMenuEnum.jcsj) )
    }
  }

  return (
    <Header
      className={styles['layout-header']}
      style={{
        padding: 0,
        backgroundColor: colorBgContainer,
        width: layoutWidth || '100%',
      }}
    >
      <div className={styles.left} style={{ width: SliderWidth }}>
        <HeaderLogoIcon />
        <span>造价云</span>
      </div>
      <div className={styles.mid}>
        {/* <div className={clsx(styles.icon, { hidden: hideSliderIcon })}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            onClick: () => setCollapsed(!collapsed),
          })}
        </div> */}
        <Tabs onChange={handTabsChange} activeKey={curHeaderMenuKey} items={items} />
      </div>
      <div className={styles.right}>
        <AvatarDropdown />
      </div>
    </Header>
  )
}
export default LayHeader
