import { Result } from 'antd'

const Forbidden = ({ style }: any) =>{
  return (
    <div style={style}>
      <Result status='403' title='403' subTitle='抱歉，您没有权限访问这个页面' extra={null} />
    </div>
  )
} 

export default Forbidden
