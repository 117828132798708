import { get, post, put } from '@/utils/request'

interface IResponse {
  message: string
  code: number
  data: any
}
export interface User {
  username: string
  password?: string
  captcha?: string
  token?: string
  loginType?: string // 产品id
}

/**
 * 登录
 * @returns
 */
export function login(data: User) {
  // return post<string>('/auth-server/auth/token', data)
  return post<any>('/cost-cloud-account/web/login', data)
}
/**
 * 获取企业列表
 */

export function getEnterprises() {
  // return get<{ enterpriseName: string; epid: number }[]>('/auth-server/auth/enterprises')
  return get<{ enterpriseName: string; epid: number }[]>('/cost-cloud-account/web/enterprises')
}

/**
 * 选择企业
 */
export function getEnterpriseInfo(data: { epid: any,token:string }) {
  // return put<any>('/auth-server/auth/enterprise', data)
  return post<any>('/cost-cloud-account/web/enterprise', data)
}

/**
 * 申请试用
 * @returns
 */
export function createApplicationApi(data) {
  return post<string>('/cost-cloud-indicator/trial_application/create', data)
}

/**
 * 查询当前租户的组织结构
 */
export function getOrgList(data) {
  return post<any>('/cost-cloud-cloudproject/org/list', data)
  // return Promise.resolve(mockData.orgTreeSourceData)
}

/**
 * 获取当前登录用户信息
 * @returns
 */
export function getUserInfo() {
  return get<User>('/auth-server/user/get-user-detail')
}

/**
 * 退出登录
 * @returns
 */
export function loginOut() {
  return get<boolean>('/auth-server/auth/logout')
}

/**
 * 验证码
 * @returns
 */
export function getCaptcha() {
  return get<{ captcha: string; token: string }>('/api/users/getCaptcha')
}

/**
 * 验证验证码
 * @returns
 */
export function verifyCaptcha(data: { token: string; captcha: string }) {
  return post<any>('/api/users/verifyCaptcha', data)
}

/**
 * 注册
 * @returns
 */
export function register(data: User) {
  return post<User>('/api/users/register', data)
}

/**
 * 获取所有用户列表
 * @returns
 */
export function getUserList() {
  return get<IResponse>('/api/users/userList')
}

const clientAuthInfo = {
  productId: 171,
  productName: 'LubanMaterials',
  alias: 'LubanMaterials',
  authInfoGroups: [
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd2',
      authOrg: '指标项目列表',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字工作台-指标采集',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [
        {
          authCode: 'project:add',
          authName: '新建项目',
        },
        {
          authCode: 'project:edit',
          authName: '编辑项目',
        },
        {
          authCode: 'project:del',
          authName: '删除项目',
        },
        {
          authCode: 'indicator:edit',
          authName: '编辑指标',
        },
        {
          authCode: 'indicator:del',
          authName: '删除指标',
        },
        {
          authCode: 'indicator:down',
          authName: '下架指标',
        },
        {
          authCode: 'indicator:add',
          authName: '新增造价类型',
        },
      ],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd3',
      authOrg: '待审核指标列表',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字工作台-指标采集',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [
        {
          authCode: 'review:examine',
          authName: '审核',
        },
      ],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd4',
      authOrg: '整体工程指标',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-企业指标库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd42',
      authOrg: '单项工程指标',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-企业指标库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd423',
      authOrg: '单位工程指标',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-企业指标库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd424',
      authOrg: '清单综合单价',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-企业指标库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd425',
      authOrg: '工料价格指标',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-企业指标库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd426',
      authOrg: '总体专业',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-数据字典',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd427',
      authOrg: '工程专业',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-数据字典',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd428',
      authOrg: '工程分类',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-数据字典',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd429',
      authOrg: '项目信息',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-数据字典',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd4291',
      authOrg: '工程特征',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-数据字典',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99cfd7f3f02486bd42911',
      authOrg: '造价类型',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-数据字典',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bbfe4ae99c1fd7f3f02486bd4291',
      authOrg: '指标模板',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-数据字典',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '17aa4ce29bbfe4ae99cfd7f3f02486bd4291',
      authOrg: '清单数据',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-基础清单定额库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [
        {
          authCode: 'inventory:setIndicator',
          authName: '设置指标',
        },
      ],
    },
    {
      authOrgId: '71aa4ce29bbfe4ae99cfd7f3f02486bd4291',
      authOrg: '定额数据',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-基础清单定额库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
    {
      authOrgId: '7aa4ce29bb1fe4ae99cfd7f3f02486bd4291',
      authOrg: '人材机数据',
      display: 1,
      webSort: 0,
      authInfoGroupParent: {
        parentAuthOrgId: '096b1a3e19874b3798df1bd712bbbe41',
        parentAuthOrgName: '数字资产-基础清单定额库',
        functionId: 'LubanMaterials-001',
        groupLevel: null,
        buy: true,
      },
      authInfos: [],
    },
  ],
  expired: false,
  packageImage: null,
  packageId: null,
}

/**
 * 获取授权信息
 * @returns
 */
export function getAuth() {
  // return get<Record<string, any>[]>('/api/menu')
  return Promise.resolve(clientAuthInfo)
}

/**
 * 获取混泥土列表
 * @returns
 */
export function getHntList(data) {
  return post<ListResT<any>>('/api/page', data)
}
