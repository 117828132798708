import MenuSzgztIcon from '@/components/icon/menuSzgzt'
import MenuQyzbkIcon from '@/components/icon/menuQyzbk'
import MenuSjzdIcon from '@/components/icon/menuSjzd'
import MenuJcqddeIcon from '@/components/icon/menujcqdde'
import MenuCostIcon from '@/components/icon/menuCost'
import { HeaderMenuEnum } from '@/config'

// 个人菜单，不参与权限
export const personMenuList = [
  {
    title: '成本方案',
    icon: <MenuCostIcon />,
    key: 'cost',
    children: [
      {
        path: '/cost/list',
        title: '成本科目库',
        key: '/cost/list',
      },
    ],
  },
]

