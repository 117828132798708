// 字段类型
export enum FieldTypeEnum {
  INPUTNUMBER = 'INPUTNUMBER', // 数字输入框
  INPUT = 'INPUT', // 文本输入框
  DATETIME = 'DATETIME', // 日期选择框
  DATETIME_RANGE = 'DATETIME_RANGE', // 日期范围选择框
  SELECT = 'SELECT', // 下拉选择框
  SELECT_MULTI = 'SELECT_MULTI', // 下拉复选
  CHECKBOX = 'CHECKBOX', // 多选框单个
  RADIO = 'RADIO', // 单选框单个
  SWITCH = 'SWITCH', // 开关 数字0或者1
  CHECKBOXGROUP = 'CHECKBOXGROUP', // 多选框组
  RADIOGROUP = 'RADIOGROUP', // 单项框组
  CASCADER= 'CASCADER', // 级联选择框
  SELECT_THIRD_PARTY = 'SELECT_THIRD_PARTY', // 第三方下拉选择框
}

// 暂时支持编辑的字段类型
export const SupportEditFieldType = [
  FieldTypeEnum.INPUTNUMBER,
  FieldTypeEnum.INPUT,
  FieldTypeEnum.DATETIME,
  FieldTypeEnum.DATETIME_RANGE,
  FieldTypeEnum.SELECT,
  FieldTypeEnum.SELECT_MULTI,
  // FieldTypeEnum.CHECKBOX,
  // FieldTypeEnum.RADIO,
  // FieldTypeEnum.SWITCH,
  FieldTypeEnum.CHECKBOXGROUP,
  FieldTypeEnum.RADIOGROUP,
  // FieldTypeEnum.SELECT_THIRD_PARTY, // 第三方下拉选择框 暂时不支持
] as FieldTypeEnum[]

// 字段类型描述
export const FieldTypeMap = {
  [FieldTypeEnum.INPUTNUMBER]: '数字输入框',
  [FieldTypeEnum.INPUT]: '文本框',
  [FieldTypeEnum.DATETIME]: '日期',
  [FieldTypeEnum.DATETIME_RANGE]: '日期范围',
  [FieldTypeEnum.SELECT]: '下拉单选',
  [FieldTypeEnum.SELECT_MULTI]: '下拉复选',
  [FieldTypeEnum.CHECKBOX]: '多选框单选',
  [FieldTypeEnum.RADIO]: '单选框',
  [FieldTypeEnum.SWITCH]: '开关 数字0或者1',
  [FieldTypeEnum.CHECKBOXGROUP]: '多选框组',
  [FieldTypeEnum.RADIOGROUP]: '单项框组',
  [FieldTypeEnum.SELECT_THIRD_PARTY]: '第三方下拉选择框',
}