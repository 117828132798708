import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import BMF from 'browser-md5-file'
// import pinyin from 'pinyin'
import { produce } from 'immer'
import { getCnPy } from '@/api/common/base'

const storage = window.localStorage

/**
 * 缓存数据
 * @param key {string} key
 * @param value {string} value
 * @returns
 */
export function setStorage(key: string, value: string) {
  return storage.setItem(key, value)
}

// 批量设置缓存
export function setStorages(prams: Record<string, string>) {
  for (const key in prams) {
    if (Object.prototype.hasOwnProperty.call(prams, key)) {
      const value = prams[key]
      storage.setItem(key, value)
    }
  }
}

/**
 * 获取缓存数据
 * @param key {string} key
 * @returns
 */
export function getStorage(key: string) {
  return storage.getItem(key)
}

/**
 * 删除缓存
 * @param key {string} key
 * @returns
 */
export function removeStorage(key: string) {
  return storage.removeItem(key)
}

export function getCookie(name: string): string {
  const cookies = document.cookie.split(';')
  const cookieObj = {} as Record<string, string>
  cookies.forEach(item => {
    if (item) {
      const arr = item.split('=')
      cookieObj[arr[0]] = arr[1] || ''
    }
  })
  return cookieObj[name]
}

// 是否是空对象
export function isEmpty(obj: any) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length
}

// 小数千分位展示
export function fmtToThousands(num: number): string {
  if (Number.isNaN(num)) {
    return ''
  }
  const parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

// 返回范围内的数组
export function range(start: number, end?: number, step?: number) {
  const result: number[] = []
  if (end === undefined) {
    end = start
    start = 0
  }
  step = step || 1
  for (let i = start; step > 0 ? i < end : i > end; i += step) {
    result.push(i)
  }

  return result
}

// 树形数据结构添加index索引
export function addTreeIndex(array: any[]) {
  let index = 0
  function add(arr: any[]) {
    return arr.map((item, i) => {
      const newItem = { ...item, index: ++index }
      if (newItem.children) {
        newItem.children = add(newItem.children)
      }
      return newItem
    })
  }
  return add(array)
}

// 获取树形结构所有子节点数据
export const getTreeChildNodes = data => {
  let arr = []
  data.forEach(item => {
    if (item.children) {
      arr = arr.concat(getTreeChildNodes(item.children))
    } else {
      arr.push(item)
    }
  })
  return arr
}

/**
 * 转成label，value格式
 * @param data
 * @returns
 */
export const normalOptionTranslate = (
  data = [],
  fields: {
    label: string
    value: string
    id?: string
  } = {
      label: 'name',
      value: 'code',
      id: 'id',
    },
) =>
  data.map(item => ({
    label: item[fields.label],
    value: item[fields.value] || item.id,
    id: item[fields.id],
  }))

// validate value
export function validateValue<T>(val: T) {
  return val !== null && val !== undefined
}

// 获取唯一id
export const getUUid = () => nanoid()

// 转换antd日期类型成时间戳
export function changeDateToTimes(date: any) {
  if (date?.toDate) {
    return date?.toDate().getTime()
  }
  if (date instanceof Date) {
    return date.getTime()
  }
  return date
}

// 业态转换成级联数据，antd Cascader
export function listToCascader(arr, pid = null) {
  const tree = []
  for (const i of arr) {
    const item = { id: i.id, value: i.code, label: i.name, pid: i.parentId } as any
    if (item.pid === pid || (typeof item.pid === 'undefined' && pid === null)) {
      const children = listToCascader(arr, item.id)
      if (children.length > 0) {
        item.children = children
      }
      tree.push(item)
    }
  }

  return tree
}

// 读取浏览器文件md5值
export const getFileMd5 = file => {
  return new Promise((resolve, reject) => {
    const bmf = new BMF()
    bmf.md5(
      file,
      (err, md5) => {
        resolve(md5)
      },
      progress => {
        // console.log('progress number:', progress)
      },
    )
  })
}

/**
 *
 * @param text 时间戳
 * @returns YYYY/MM/DD格式的日期
 */
export const formatDay = text => {
  if (!text) {
    return ''
  }
  return dayjs(text).format('YYYY/MM/DD')
}

/**
 * 将省、市、区拼接为字符串，以空格隔开
 * @param locationProvCodeText 省
 * @param locationCityCodeText 市
 * @param locationAreaCodeText 区
 * @returns 拼接后的字符串，以空格隔开
 */
export const renderLocation = ({
  locationProvCodeText,
  locationCityCodeText,
  locationAreaCodeText,
}) => {
  const parts = []
  if (locationProvCodeText) {
    parts.push(locationProvCodeText)
  }
  if (locationCityCodeText) {
    parts.push(locationCityCodeText)
  }
  if (locationAreaCodeText) {
    parts.push(locationAreaCodeText)
  }
  return parts.join(' ')
}

// 数组里根据id一直向上查找，并返回到根节点的所有节点.
// const arr = [{ id: 2, pid: 1},{ id: 1, pid: '0'}]
// findAncestors(arr,2)  =》 [1,2]
// 获取项目组成业态的所有级联节点
export function getComposeAncestorsArray(arr, id) {
  const result = [] // 存储结果的数组
  const map = {} // ID到节点的映射

  // 创建ID到节点的映射
  for (const item of arr) {
    map[item.id] = item
  }

  let currentId = id
  // 判断根节点条件。根据条件扩展
  while (currentId !== '0') {
    const item = map[currentId]
    if (item) {
      result.unshift(item) // 将当前节点插入到结果数组的开头
      currentId = item.parentId // 更新当前ID为父ID
    } else {
      break // 找不到对应的节点，跳出循环
    }
  }

  return result
}

export function isUndefined(v) {
  return typeof v === 'undefined'
}

export function isNull(v) {
  return v === null
}


export function isValidString(value) {
  return typeof value === 'string' && value.trim().length > 0
}

export function isValidNumber(value) {
  return typeof value === 'number' && !Number.isNaN(value) && value !== null
}


export function memo(func) {
  const cache = {}

  return function (...args) {
    const key = JSON.stringify(args)

    if (cache[key]) {
      return cache[key]
    }

    const result = func.apply(this, args)
    cache[key] = result

    return result
  }
}

// 获取英文首字母
// function getEnglishInitials(str) {
//   return import("pinyin").then((module) => {
//     const pinyin = module.default as any

//     if (!str || typeof str !== 'string') {
//       return ''
//     }

//     const pinyinArr = pinyin(str, {
//       style: pinyin.STYLE_FIRST_LETTER,
//     })

//     let result = ''
//     for (let i = 0; i < pinyinArr.length; i++) {
//       const initials = pinyinArr[i][0]
//       if (initials) {
//         result += initials
//       }
//     }

//     return result.toUpperCase()
//   })
// }

// 中文转拼音首字母
export async function chineseToEnglishInitials(str) {
  const result = await getCnPy({
    cnNameList: [str],
  })
  if (result.length) {
    return result[0].pyFirstName
  }
  return ''
}

// 交换数组
export const swapArr = ({
  positionArr, // 定位数组，用于定位交换的元素位置。树形结构需要，扁平结构和原始数组相同
  sourceArr, // 原始数组
  id, // 当前交换的元素id
  direction, // 交换方向 up:上移 down:下移
  sort, // 排序字段
}: any) => {
  const posArr = positionArr || sourceArr // 如果没有定位数组，使用原始数组
  const index = posArr.findIndex(item => item.id === id)
  const swapItem = direction === 'up' ? posArr[index - 1] : posArr[index + 1]
  const res = produce(sourceArr, draft => {
    const itemIndex = draft.findIndex(i => i.id === id)
    const targetIndex = draft.findIndex(i => i.id === swapItem.id)
    // 交换并排序
    const temp = draft[itemIndex][sort]
    draft[itemIndex].serialNum = draft[targetIndex][sort]
    draft[targetIndex].serialNum = temp
    draft.sort((a, b) => a[sort] - b[sort])
  })
  return res as any
}

// 数组去重
export function uniq(arr) {
  return [...new Set(arr)]
}

// 睡一会
export function sleep(ms) {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

/**
 * 将字节转换为GB、MB或KB，根据字节数的大小进行判断。
 * @param {number} bytes - 要转换的字节数
 * @returns {string} - 带有单位的字符串（GB、MB 或 KB）
 */
export function formatBytes(bytes, precision = 2) {
  // 判断字节数是否大于等于1 GB（1024 MB * 1024 KB * 1024 字节）
  if (bytes >= 1024 ** 3) {
    // 将字节数除以 1024 ** 3 并保留两位小数，然后附加单位 "GB" 并返回
    return `${(bytes / 1024 ** 3).toFixed(precision)} GB`
  }
  // 判断字节数是否大于等于1 MB（1024 KB * 1024 字节）
  if (bytes >= 1024 ** 2) {
    // 将字节数除以 1024 ** 2 并保留两位小数，然后附加单位 "MB" 并返回
    return `${(bytes / 1024 ** 2).toFixed(precision)} MB`
  }
  // 判断字节数是否大于等于1 KB（1024 字节）
  if (bytes >= 1024) {
    // 将字节数除以 1024 并保留两位小数，然后附加单位 "KB" 并返回
    return `${(bytes / 1024).toFixed(precision)} KB`
  }
  // 字节数小于1 KB，直接返回字节数并附加单位 "B"

  return `${bytes} B`
}

export function JSONParse(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('JSON解析错误:', error);
    return null; // 或者你可以选择抛出自定义异常或采取其他处理方式
  }
}

/* eslint-disable */
export function downloadFile(file, fileName) {
  // 创建一个指向该 File 对象的 URL
  let url = URL.createObjectURL(file);

  // 创建一个用于下载的链接
  let a = document.createElement("a");
  a.href = url;
  a.download = fileName || file.name;
  document.body.appendChild(a); // 临时添加到页面中

  // 触发点击事件以开始下载
  a.click();

  // eslint-disable
  // 清理
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
    a.remove(); // 从页面中移除链接
  }, 0);
}

// 解析json
export function jsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error(`JSON解析失败：${error.message}`);
    return {};
  }
}
