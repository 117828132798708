import useGlobalStore from '@/store/useGlobalStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthConfig, PublicPath } from '@/config/auth'
import { getEnterpriseInfo } from '@/api'
import { getStorage, setStorage, sleep } from '@/utils/tools'
import { message } from 'antd'
import useCloudStore from '@/pages/cloudProject/store'
import { produce } from 'immer'
import { getAuthPathByCodes, isPersonal } from '@/utils/help'

export function useFetch() {
  const navigate = useNavigate()

  const { resetStore: resetCloudStore } = useCloudStore()
  const setGlobalState = useGlobalStore.setState
  const { curHeaderMenuKey, setAuthorized, setGlobalInfo, setAuthCodes, menuKeysMap } =
    useGlobalStore(s=>({
      curHeaderMenuKey: s.curHeaderMenuKey,
      setAuthorized: s.setAuthorized,
      setGlobalInfo: s.setGlobalInfo,
      setAuthCodes: s.setAuthCodes,
      resetGlobalStore: s.resetGlobalStore,
      menuKeysMap:s.menuKeysMap
    }))

  // 登录或者刷新时选择企业
  const selectEnterprise = async (epid: any, options?,callback?) => {
    const { isRefresh } = options || {}
    let enterpriseInfo = null
    let newTenantId = null
    try {
      let enterpriseRes = null
      enterpriseRes = await getEnterpriseInfo({
        epid,
        token: getStorage('token') || '',
      })


      if (enterpriseRes) {
        enterpriseInfo = enterpriseRes.enterpriseInfo
        newTenantId = enterpriseRes.tenantId
      } else {
        message.warning(
          `你的账号在${getStorage('enterpriseName')}的授权已失效。已为你切换到默认用户空间！` ||
            enterpriseRes.message,
        )
        await sleep(1000)
        enterpriseRes = await getEnterpriseInfo({
          epid: null,
          token: getStorage('token') || '',
        })
        if (enterpriseRes) {
          enterpriseInfo = enterpriseRes.enterpriseInfo
          newTenantId = enterpriseRes.tenantId
        }
      }

      setGlobalInfo({
        enterpriseInfo,
        tenantId: newTenantId,
      })
      setStorage('tenantId', newTenantId)
      setStorage('enterpriseName', enterpriseInfo?.nameCn)
      const newAuthCodes = enterpriseInfo?.authCodes || ''
      if (newAuthCodes) {
        setAuthCodes(newAuthCodes.split(';'))
      } else {
        setAuthCodes([])
      }

      // 计算菜单权限
      // 所有有权限的config
      const authPaths =getAuthPathByCodes(newAuthCodes)
      // 根据权限配置计算菜单
      const newMenuKeysMap = produce(menuKeysMap, draftState => {
        Object.keys(draftState).forEach(key => {
          const menus = draftState[key] // 对于模块菜单列表
          menus.forEach(item => {
            item.children = item.children.filter(child => {
                return authPaths?.includes(child.path)
            })
          })
          draftState[key]  =draftState[key].filter(item => item.children.length > 0)
          // return draftState.filter(item => item.children.length > 0)

        })
      });
      setGlobalState({
        menuKeysMap:newMenuKeysMap
      })


      // 不是刷新，目前只有登录才会到这里
      if(!isRefresh){
        if (newTenantId?.startsWith('E')) {
          navigate('/cloud-project/list')
          // navigate('/template/list')
        } else {
          navigate('/cost/list')
        }
      }

      // TODO: 刷新uselocation 拿到还是旧的路由，导致权限出现异常页面，原因不明
      await sleep(200)
      setAuthorized(true)

    } catch (error) {
      console.log(error)
    }
  }

  // 下拉选择企业
  const changeEnterprise = async (epid: any, callback?) => {
    try {
      const enterpriseRes = await getEnterpriseInfo({
        epid,
        token: getStorage('token') || '',
      })
      if (enterpriseRes) {
        const enterpriseInfo = enterpriseRes.enterpriseInfo
        const newTenantId = enterpriseRes.tenantId
        setStorage('tenantId', newTenantId)
        setStorage('enterpriseName', enterpriseInfo?.nameCn)
        if(isPersonal()){
          navigate('/cost/list')
        }
        window.location.reload(); // 刷新页面，清空store
      }
    } catch (error) {
      console.log(error)
    }
  }

  return { selectEnterprise, changeEnterprise }
}
