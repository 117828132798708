import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

/* eslint-disable */
const Svg = () => (
  // eslint-disable-next-line
  <svg
    width='19px'
    height='19px'
    viewBox='0 0 19 19'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='数字资产' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='04' transform='translate(-706.000000, -148.000000)'>
        <g id='侧边栏icon基础清单定额库/未选中' transform='translate(706.459239, 148.437500)'>
          <rect id='矩形' fill='currentColor' opacity='0' x='0' y='0' width='18' height='18'></rect>
          <g id='编组-12' transform='translate(2.000000, 0.226868)'>
            <rect
              id='矩形'
              stroke='currentColor'
              strokeWidth='1.5'
              fill='#FFFFFF'
              x='0.75'
              y='2.79626465'
              width='12.5'
              height='14'
              rx='0.5'
            ></rect>
            <rect
              id='矩形'
              fill='currentColor'
              x='5.42403765'
              y='6.53430176'
              width='5.55444336'
              height='1.5'
              rx='0.5'
            ></rect>
            <rect
              id='矩形备份-20'
              fill='currentColor'
              x='5.42403765'
              y='9.04626465'
              width='5.55444336'
              height='1.5'
              rx='0.5'
            ></rect>
            <rect
              id='矩形备份-21'
              fill='currentColor'
              x='5.42403765'
              y='11.5582275'
              width='5.55444336'
              height='1.5'
              rx='0.5'
            ></rect>
            <circle id='椭圆形' fill='currentColor' cx='3.75' cy='7.28430176' r='1'></circle>
            <circle id='椭圆形备份-6' fill='currentColor' cx='3.75' cy='9.79626465' r='1'></circle>
            <circle id='椭圆形备份-7' fill='currentColor' cx='3.75' cy='12.3082275' r='1'></circle>
            <path
              d='M7,0.75 C7.24496622,0.75 7.47067477,0.832663688 7.65046905,0.971918774 C7.97240983,1.2212698 8.14878952,1.65055847 8.02274896,2.11089572 L8.02274896,2.11089572 L9.86468838,2.11030962 L10.1241093,3.66683531 L4.12808908,4.06657 L3.85661895,2.43774923 L5.94809165,1.97209185 C5.88844377,1.56542016 6.06020703,1.19600724 6.34953095,0.971918774 C6.52932523,0.832663688 6.75503378,0.75 7,0.75 Z'
              id='形状结合'
              stroke='currentColor'
              strokeWidth='1.5'
              fill='#FFFFFF'
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const IconComponent = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Svg} {...props} />
)

export default IconComponent
