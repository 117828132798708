import { redirect, Navigate, Route } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import Layout from '@/components/Layouts/BaseLayout'
import ErrorPage from '@/pages/ErrorPage'
import Login from '@/pages/login'
// import IndicatorList from '@/pages/indicator/list'
// import IndicatorAdd from '@/pages/indicator/edit'
// import IndicatorExamine from '@/pages/indicator/examine'
// import IndicatorReviewList from '@/pages/indicator/reviewList'
// import EnterpriseMaterial from '@/pages/enterprise/material'
// import EnterpriseInventory from '@/pages/enterprise/inventory'
// import EnterpriseProject from '@/pages/enterprise/project'
// import EnterpriseSingle from '@/pages/enterprise/single'
// import EnterpriseUnit from '@/pages/enterprise/unit'
// import DictFeature from '@/pages/dict/engineeringFeature'
// import DictInfo from '@/pages/dict/projectInfo'
// import DictProject from '@/pages/dict/engineeringSpecialty'
// import DictSort from '@/pages/dict/engineeringClassification'
// import DictTemplate from '@/pages/dict/engineeringQuantityIndicator'
// import DictTotal from '@/pages/dict/overallMajor'
// import DictType from '@/pages/dict/costType'

// import InventoryList from '@/pages/inventory/list'
// import SetIndicator from '@/pages/inventory/components/setIndicator/main'
// import RationList from '@/pages/inventory/ration'
// import LaborMaterialsMachinery from '@/pages/inventory/laborMaterialsMachinery'

// import CostPlanList from '@/pages/cost/list'
// import SingleCompare from '@/pages/compare/single'
// import MultiCompare from '@/pages/compare/multi'

import NoFind from '@/pages/NoFind'
import TestPage from '@/pages/test'
import { getStorage } from '@/utils/tools'
// import SplitPlanList from '@/pages/cost/split'
// import TemplateList from '@/pages/template/list'
// import GvTemplateList from '@/pages/gvTemplate/list'
// import GvTemplateSetting from '@/pages/gvTemplate/setting'
// import FieldList from '@/pages/field/list'
import { DefaultHeaderKey } from '@/config'
import Loading from '@/pages/other/Loading'

const SplitPlanList = lazy(() => import('@/pages/cost/split'))

const EnterpriseSingle = lazy(() => import('@/pages/enterprise/single'))
const EnterpriseUnit = lazy(() => import('@/pages/enterprise/unit'))
const DictFeature = lazy(() => import('@/pages/dict/engineeringFeature'))
const DictInfo = lazy(() => import('@/pages/dict/projectInfo'))
const DictProject = lazy(() => import('@/pages/dict/engineeringSpecialty'))
const DictSort = lazy(() => import('@/pages/dict/engineeringClassification'))
const DictTemplate = lazy(() => import('@/pages/dict/engineeringQuantityIndicator'))
const DictTotal = lazy(() => import('@/pages/dict/overallMajor'))
const DictType = lazy(() => import('@/pages/dict/costType'))
const DictProfessional = lazy(() => import('@/pages/dict/professionalInformation'))

const IndicatorList = lazy(() => import('@/pages/indicator/list'))
const IndicatorAdd = lazy(() => import('@/pages/indicator/edit'))
const IndicatorExamine = lazy(() => import('@/pages/indicator/examine'))
const IndicatorReviewList = lazy(() => import('@/pages/indicator/reviewList'))
const EnterpriseMaterial = lazy(() => import('@/pages/enterprise/material'))
const EnterpriseInventory = lazy(() => import('@/pages/enterprise/inventory'))
const EnterpriseProject = lazy(() => import('@/pages/enterprise/project'))
const CostPlanList = lazy(() => import('@/pages/cost/list'))
const SingleCompare = lazy(() => import('@/pages/compare/single'))
const MultiCompare = lazy(() => import('@/pages/compare/multi'))

const GvTemplateList = lazy(() => import('@/pages/gvTemplate/list'))
const TemplateList = lazy(() => import('@/pages/template/list'))
const LaborMaterialsMachinery = lazy(() => import('@/pages/inventory/laborMaterialsMachinery'))
const RationList = lazy(() => import('@/pages/inventory/ration'))
const SetIndicator = lazy(() => import('@/pages/inventory/components/setIndicator/main'))
const InventoryList = lazy(() => import('@/pages/inventory/list'))
const GvTemplateSetting = lazy(() => import('@/pages/gvTemplate/setting'))
const FieldList = lazy(() => import('@/pages/field/list'))
const PerformanceList = lazy(() => import('@/pages/cloudProject/index'))

// 新标签页面
const Report = lazy(() => import('@/pages/Report'))
const ReportQdTab = lazy(() => import('@/pages/Report/newTab'))

const lazyLoad = (children: React.ReactNode) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
)

const rootLoader = async () => {
  // 首次访问直接跳转到登录页
  const token = getStorage('token')
  if (!token) {
    return redirect('/login')
  }
  // 这里是顶部菜单的key，用于高亮。如果当前路由不在这个key的列表里，需要跳转到默认的key到路由里
  // console.log('DefaultHeaderKey')
  // console.log(DefaultHeaderKey)
  // 所以这里获取到当前路由，如果路由不是当前菜单的，需要做处理
  return null
}

const routes = (
  <>
    {/* <Route path='/' loader={rootLoader} element={<Layout />} id='root' errorElement={<ErrorPage />}> */}
    <Route path='/' loader={rootLoader} element={<Layout />} id='root' errorElement={<ErrorPage />}>
      {/* FIXME: 去掉 errorElement 试试 */}
      {/* <Route path='/' loader={rootLoader} element={<Layout />} id='root' > */}
      {/* 默认跳转到指标项目列表 */}
      {/* <Route index element={<Navigate to='cloud-project/list' />} /> */}
      <Route path='/' element={<Navigate to='/cloud-project/list' replace />} />
      {/* 项目指标 */}
      <Route path='indicator'>
        <Route index element={<Navigate to='list' />} />
        <Route path='list' element={lazyLoad(<IndicatorList />)} />
        {/* 报表 */}
        {/* <Route path='report' element={lazyLoad(<Report />)} /> */}
        <Route path='add' element={lazyLoad(<IndicatorAdd />)} />
        <Route path='edit' element={lazyLoad(<IndicatorAdd />)} />
        <Route path='reviewList' element={lazyLoad(<IndicatorReviewList />)} />
        <Route path='viewExamine' element={lazyLoad(<IndicatorExamine />)} />
        <Route path='examine' element={lazyLoad(<IndicatorExamine />)} />
        <Route path='detail' element={lazyLoad(<IndicatorExamine />)} />
        <Route path='singlecompare' element={lazyLoad(<SingleCompare />)} />
        <Route path='reviewSingle' element={lazyLoad(<SingleCompare />)} />
        <Route path='multicompare' element={lazyLoad(<MultiCompare />)} />
        <Route path='reviewMulti' element={lazyLoad(<MultiCompare />)} />
      </Route>
      {/* 企业指标 */}
      <Route path='enterprise'>
        <Route index path='project' element={lazyLoad(<EnterpriseProject />)} />
        <Route path='single' element={lazyLoad(<EnterpriseSingle />)} />
        <Route path='unit' element={lazyLoad(<EnterpriseUnit />)} />
        <Route path='inventory' element={lazyLoad(<EnterpriseInventory />)} />
        <Route path='material' element={lazyLoad(<EnterpriseMaterial />)} />
      </Route>
      {/* 字典 */}
      <Route path='dict'>
        <Route index path='total' element={lazyLoad(<DictTotal />)} />
        <Route path='project' element={lazyLoad(<DictProject />)} />
        <Route path='sort' element={lazyLoad(<DictSort />)} />
        <Route path='info' element={lazyLoad(<DictInfo />)} />
        <Route path='feature' element={lazyLoad(<DictFeature />)} />
        <Route path='type' element={lazyLoad(<DictType />)} />
        <Route path='template' element={lazyLoad(<DictTemplate />)} />
        <Route path='professional' element={lazyLoad(<DictProfessional />)} />
      </Route>
      {/* 清单定额 */}
      <Route path='inventory'>
        <Route index path='list' element={lazyLoad(<InventoryList />)} />
        <Route path='setIndicator' element={lazyLoad(<SetIndicator />)} />
        <Route path='ration' element={lazyLoad(<RationList />)} />
        <Route path='laborMaterialsMachinery' element={lazyLoad(<LaborMaterialsMachinery />)} />
      </Route>

      {/* 成本方案 */}
      <Route path='cost'>
        <Route index path='list' element={lazyLoad(<CostPlanList />)} />
        <Route path='split' element={lazyLoad(<SplitPlanList />)} />
      </Route>
      {/* 基础配置-项目模板 */}
      <Route path='template'>
        <Route index path='list' element={lazyLoad(<TemplateList />)} />
      </Route>
      <Route path='field'>
        <Route index path='list' element={lazyLoad(<FieldList />)} />
      </Route>

      {/* 基础配置-政府发布指标模板 */}
      <Route path='official-template'>
        <Route index path='list' element={lazyLoad(<GvTemplateList />)} />
      </Route>
      {/* <Route path='field'>
        <Route index path='list' element={<FieldList />} />
      </Route> */}

      {/* 项目登记 */}
      <Route path='cloud-project'>
        <Route index path='list' element={lazyLoad(<PerformanceList />)} />
      </Route>
      <Route path='*' element={<NoFind />} />
    </Route>
    <Route path='/login' element={lazyLoad(<Login />)} />
    {/* 无权限路由 */}

    <Route path='test' element={<TestPage />} />
    <Route path='qdTab' element={<ReportQdTab />} />
    <Route index path='official-template/setting' element={lazyLoad(<GvTemplateSetting />)} />
    {/* <Route path='*' element={<NoFind />} /> */}
  </>
)

export default routes
