// 面包屑配置。没有配置就不展示
export const BreadcrumbNameMap: Record<string, string> = {
  '/indicator': '数字工作台',
  // '/indicator/list': '指标项目列表',
  '/indicator/edit': '编辑',
  // '/indicator/reviewList': '待审核指标列表',
  '/indicator/examine': '审核',
  '/baseInfo': '基本信息',
  '/inventory': '清单',
  '/inventory/setIndicator': '设置指标',
}

export enum HeaderMenuEnum {
  zyzx = 'zyzx', // 作业中心
  szzc = 'szzc', // 数字资产
  jcsj = 'jcsj', // 基础数据
}



// header 点击和默认路径配置
export const HeaderPathMap = {
  [HeaderMenuEnum.zyzx]: '/cloud-project/list',
  [HeaderMenuEnum.szzc]: '/indicator/list',
  [HeaderMenuEnum.jcsj]: '/template/list',
}

// 默认顶部菜单key值
export const DefaultHeaderKey = HeaderMenuEnum.zyzx
// export const DefaultHeaderKey = HeaderMenuEnum.jcsj

// 菜单默认打开key
export const DefaultOpenKeys = 'indicator'

// 菜单高亮 {当前路由：(菜单url|菜单key)}
export const SelectedKeysMap = {
  '/indicator/list': '/indicator/list',
  '/indicator/add': '/indicator/list',
  '/indicator/edit': '/indicator/list',
  '/indicator/detail': '/indicator/list',
  '/indicator/multicompare': '/indicator/list',
  '/indicator/singlecompare': '/indicator/list',
  '/indicator/reviewList': '/indicator/reviewList',
  '/indicator/examine': '/indicator/reviewList',
  '/indicator/viewExamine': '/indicator/reviewList',
  '/indicator/reviewSingle': '/indicator/reviewList',
  '/indicator/reviewMulti': '/indicator/reviewList',
  '/enterprise/project': '/enterprise/project',
  '/enterprise/single': '/enterprise/single',
  '/enterprise/unit': '/enterprise/unit',
  '/enterprise/inventory': '/enterprise/inventory',
  '/enterprise/material': '/enterprise/material',
  '/dict/feature': '/dict/feature',
  '/dict/info': '/dict/info',
  '/dict/project': '/dict/project',
  '/dict/sort': '/dict/sort',
  '/dict/template': '/dict/template',
  '/dict/professional': '/dict/professional',
  '/dict/total': '/dict/total',
  '/dict/type': '/dict/type',
  '/inventory/list': '/inventory/list',
  '/inventory/ration': '/inventory/ration',
  '/inventory/laborMaterialsMachinery': '/inventory/laborMaterialsMachinery',
  '/cost/list': '/cost/list',
  '/cost/split': '/cost/split',
  '/register': '/register',
  '/template/list': '/template/list',
  '/cloud-project/list': '/cloud-project/list',
  '/cloudProject/list': '/cloudProject/list',
  '/field/list': '/field/list',
  '/official-template/list': '/official-template/list',
  '/official-template/open': '/official-template/list',
  '/official-template/setting': '/official-template/list',
}

// 侧边栏宽度
export const SliderWidth = '208px'

// 列表默认展示多少条数据
export const tableListDefaultPageSize = 15
// 导航菜单高度
export const HeightHeader = 64
