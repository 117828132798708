import { ReactNode } from 'react'
import { Typography } from 'antd'

const { Link } = Typography

const LinkText = ({
  onClick,
  children,
  style,
  className,
}: {
  onClick
  children: ReactNode
  style?
  className?
}) => {
  return (
    // eslint-disable-next-line
    <Link onClick={onClick} className={className} style={style}>
      {children}
    </Link>
  )
}

export default LinkText
