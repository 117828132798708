import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

/* eslint-disable */
const Svg = () => (
  // eslint-disable-next-line
  <svg
    width='16px'
    height='16px'
    viewBox='0 0 19 19'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='数字资产' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='04' transform='translate(-255.000000, -148.000000)'>
        <g id='侧边栏icon企业指标库/未选中' transform='translate(255.187500, 148.437500)'>
          <rect id='矩形' fill='currentColor' opacity='0' x='0' y='0' width='18' height='18'></rect>
          <g id='编组-6'>
            <path
              d='M4,13.9912109 L7,13.9912109 C7.27614237,13.9912109 7.5,14.2150686 7.5,14.4912109 L7.5,16.5882161 L7.5,16.5882161 L3.5,16.5882161 L3.5,14.4912109 C3.5,14.2150686 3.72385763,13.9912109 4,13.9912109 Z'
              id='矩形'
              fill='currentColor'
            ></path>
            <path
              d='M9.94421339,1.30374705 L10.2451485,17.25 L0.75,17.25 L0.75,5.48293495 L9.94421339,1.30374705 Z'
              id='矩形'
              stroke='currentColor'
              strokeWidth='1.5'
            ></path>
            <path
              d='M13.75,6.72865599 L17.0288027,8.48053864 L17.2436501,17.25 L13.75,17.25 L13.75,6.72865599 Z'
              id='矩形备份'
              stroke='currentColor'
              strokeWidth='1.5'
            ></path>
            <polyline
              id='路径-3'
              stroke='currentColor'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
              points='2.82695312 11.1169271 4.45146484 8.64625323 5.96315104 9.81484375 8.17304688 6.8171875'
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const IconComponent = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Svg} {...props} />
)

export default IconComponent
