import { HeaderMenuEnum } from "."

export const PublicPath = [
  '/', // 首页
  '/indicator/detail', // 指标详情
  '/indicator/viewExamine', // 查看审核
]

// 个人空间路径，个人路径不受权限控制
export const PersonalPath = [
  '/cost/list', // 指标详情
]


// 个人菜单 取 menuList key值。固定没有权限限制
export const PersonMenuKeys = ['cost', 'cost/list']

// 权限配置，code，name,路由，多对多。
export const AuthConfig = [

  // 指标项目列表
  { path: '/indicator/list', headMenuKey: HeaderMenuEnum.szzc, code: '630:desk:proj:query', name: '指标项目列表' },
  // { path: '/indicator/detail', name: '指标项目详情' },
  { path: '/indicator/edit', headMenuKey: HeaderMenuEnum.szzc, code: '630:desk:proj:updateindicator', name: '指标编辑' },
  { path: '/indicator/add', headMenuKey: HeaderMenuEnum.szzc, code: '630:desk:proj:addcosttype', name: '新增造价类型' },
  // 待审核指标列表
  { path: '/indicator/reviewList', headMenuKey: HeaderMenuEnum.szzc, code: '630:desk:todo:query', name: '待审核指标列表' },
  { path: '/indicator/examine', headMenuKey: HeaderMenuEnum.szzc, code: '630:desk:todo:approve', name: '指标审核' },
  { path: '/indicator/report', headMenuKey: HeaderMenuEnum.szzc, code: '', name: '' },

  // 企业指标
  { path: '/enterprise/project', headMenuKey: HeaderMenuEnum.szzc, code: '630:library:proj:query', name: '整体工程指标' },
  { path: '/enterprise/single', headMenuKey: HeaderMenuEnum.szzc, code: '630:library:dx:query', name: '单项工程指标' },
  { path: '/enterprise/unit', headMenuKey: HeaderMenuEnum.szzc, code: '630:library:dw:query', name: '单位工程指标' },
  { path: '/enterprise/inventory', headMenuKey: HeaderMenuEnum.szzc, code: '630:library:qd:query', name: '清单综合单价' },
  { path: '/enterprise/material', headMenuKey: HeaderMenuEnum.szzc, code: '630:library:glj:query', name: '工料价格指标' },

  // 数据字典
  { path: '/dict/professional', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:ztzy:query' || '630:dict:gczy:query' || '630:dict:zjlx:query', name: '专业信息' },
  { path: '/dict/total', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:ztzy:query', name: '总体专业' },
  { path: '/dict/project', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:gczy:query', name: '工程专业' },
  { path: '/dict/sort', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:gcfl:query', name: '工程分类' },
  { path: '/dict/info', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:xmxx:query', name: '项目信息' },
  { path: '/dict/feature', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:gctz:query', name: '工程特征' },
  { path: '/dict/type', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zjlx:query', name: '造价类型' },
  { path: '/dict/template', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zbmb:query', name: '指标模板' },
  { path: '/official-template/list', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zfzbmb:query', name: '政府指标模板' },
  { path: '/official-template/open', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zfzbmb:open', name: '政府指标模板开放' },

  // 基础清单定额库
  { path: '/inventory/list', headMenuKey: HeaderMenuEnum.szzc, code: '630:basicqdde:qd:query', name: '清单数据' },
  { path: '/inventory/ration', headMenuKey: HeaderMenuEnum.szzc, code: '630:basicqdde:de:query', name: '定额数据' },
  {
    path: '/inventory/laborMaterialsMachinery',
    code: '630:basicqdde:rcj:query',
    headMenuKey: HeaderMenuEnum.szzc,
    name: '人材机数据',
  },

  // 成本科目库
  { path: '/cost/list', headMenuKey: HeaderMenuEnum.szzc, code: '630:costplan:km:query', name: '成本科目库' },
  { path: '/cost/split', headMenuKey: HeaderMenuEnum.szzc, code: '630:costplan:zdcf:query', name: '自动拆分方案' },

  // 项目模板
  { path: '/template/list', headMenuKey: HeaderMenuEnum.jcsj, code: '630:basicnf:projtempl:query', name: '项目模板' },
  { path: '/field/list', headMenuKey: HeaderMenuEnum.jcsj, code: '', name: '字段设置' },

  // 政府发布指标模板
  // { path: '/official-template/list', headMenuKey: HeaderMenuEnum.jcsj, code: '', name: '政府指标模板' },
  // { path: '/official-template/open', headMenuKey: HeaderMenuEnum.jcsj, code: '', name: '政府指标模板开放' },
  // { path: '/official-template/setting', headMenuKey: HeaderMenuEnum.jcsj, code: '', name: '政府指标模板设置' },
  // { path: '/field/list',headMenuKey:HeaderMenuEnum.jcsj, code: '', name: '字段设置' },

  // 设置模板


  // 项目登记
  // { path: '/cloud-project/list', code: '630:jobcenter:drp:query', name: '项目登记' },
  { path: '/cloud-project/list', headMenuKey: HeaderMenuEnum.zyzx, code: '', name: '项目登记' },

  // 项目对比
  { path: '/indicator/singlecompare', headMenuKey: HeaderMenuEnum.szzc, code: '', name: '单项目对比' },
  { path: '/indicator/multicompare', headMenuKey: HeaderMenuEnum.szzc, code: '', name: '多项目对比' },
  { path: '/indicator/reviewSingle', headMenuKey: HeaderMenuEnum.szzc, code: '', name: '多项目对比' },
  { path: '/indicator/reviewMulti', headMenuKey: HeaderMenuEnum.szzc, code: '', name: '多项目对比' },

  // 测试
  { path: '/test', code: '', name: '测试' }
]
