import { createApplicationApi } from '@/api'
import validator from '@/utils/validator'
import { Button, Form, Input, Modal, Select } from 'antd'
import React, { useState } from 'react'

import styles from './index.module.less'

interface Props {
  open: boolean
  onCancel: () => void
}

const companyTypeOpt = [
  {
    value: 0,
    label: '咨询公司',
  },
  {
    value: 1,
    label: '施工单位',
  },
  {
    value: 2,
    label: '建设单位',
  },
]

const ApplyModal = ({ open, onCancel }: Props) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)


  const onConfirm = values => {
    setLoading(true)
    const { companyType, companyName, contactPerson, passportAccount, contactInfo, email } = values
    createApplicationApi({
      companyType,
      companyName,
      contactPerson,
      passportAccount,
      contactInfo,
      email,
    })
      .then(res => {
        onCancel()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCancel = () => {
    form.resetFields()
    onCancel()
   }

  return (
    <Modal
      open={open}
      title='申请试用'
      footer={null}
      closable
      onCancel={handleCancel}
      className={styles['apply-content']}
      centered
    >
      <Form
        form={form}
        onFinish={onConfirm}
        colon={false}
        autoComplete='off'
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          name='companyType'
          label='公司类型'
          rules={[{ required: true, message: '请选择公司类型' }]}
        >
          <Select placeholder='请选择公司类型' options={companyTypeOpt} />
        </Form.Item>
        <Form.Item
          name='companyName'
          label='公司名称'
          rules={[{ required: true, message: '请输入公司名称' }, validator.inputMaxLimit(20)]}
        >
          <Input placeholder='请输入公司名称' />
        </Form.Item>
        <Form.Item
          name='contactPerson'
          label='联系人'
          rules={[{ required: true, message: '请输入联系人' }, validator.inputMaxLimit(20)]}
        >
          <Input placeholder='请输入联系人' />
        </Form.Item>
        <Form.Item name='passportAccount' label='通行证账号'>
          <Input placeholder='请输入通行证账号' />
        </Form.Item>
        <Form.Item
          name='contactInfo'
          label='手机号码'
          required={true}
          rules={[validator.validatePhoneNumber]}
        >
          <Input placeholder='请输入手机号码' />
        </Form.Item>
        <Form.Item name='email' label='邮箱'>
          <Input placeholder='请输入邮箱' />
        </Form.Item>
        <Form.Item className={styles.btn}>
          <Button key='cancel' onClick={handleCancel}>
            取消
          </Button>
          <Button
            loading={loading}
            key='confirm'
            type='primary'
            htmlType='submit'
            // onClick={() => {
            //   setIsAddTypeBtn(false)
            // }}
            className='ml-15'
          >
            确认
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ApplyModal
