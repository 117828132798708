import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

/* eslint-disable */
const Svg = () => (
  // eslint-disable-next-line
  <svg
    width='18px'
    height='19px'
    viewBox='0 0 18 19'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>侧边栏icon数字工作台/未选中</title>
    <g id='数字资产' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='04' transform='translate(-105.000000, -148.000000)'>
        <g id='侧边栏icon数字工作台/未选中' transform='translate(105.000000, 148.437500)'>
          <rect id='矩形' fill='#FFFFFF' opacity='0' x='0' y='0' width='18' height='18'></rect>
          <g id='编组-3'>
            <path
              d='M17.5,0 C17.7761424,0 18,0.223857625 18,0.5 L18,12.5 C18,12.7761424 17.7761424,13 17.5,13 L0.5,13 C0.223857625,13 0,12.7761424 0,12.5 L0,0.5 C0,0.223857625 0.223857625,0 0.5,0 L17.5,0 Z M16.5,1.5 L1.5,1.5 L1.5,11.5 L16.5,11.5 L16.5,1.5 Z'
              id='矩形'
              fill='currentColor'
              fillRule='nonzero'
            ></path>
            <rect
              id='矩形'
              fill='currentColor'
              x='3.75'
              y='3.24906783'
              width='1.5'
              height='6.50186435'
              rx='0.5'
            ></rect>
            <rect
              id='矩形备份'
              fill='currentColor'
              x='12.75'
              y='3.24906783'
              width='1.5'
              height='6.50186435'
              rx='0.5'
            ></rect>
            <rect
              id='矩形备份-2'
              fill='currentColor'
              x='8.25'
              y='4.75093217'
              width='1.5'
              height='5'
              rx='0.5'
            ></rect>
            <path
              d='M11,15 C11.1573787,15 11.3055728,15.0740971 11.4,15.2 L12.9,17.2 C13.0656854,17.4209139 13.0209139,17.7343146 12.8,17.9 C12.7134519,17.9649111 12.6081851,18 12.5,18 L5.5,18 C5.22385763,18 5,17.7761424 5,17.5 C5,17.3918149 5.03508894,17.2865481 5.1,17.2 L6.6,15.2 C6.69442719,15.0740971 6.84262135,15 7,15 L11,15 Z'
              id='矩形'
              fill='currentColor'
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const IconComponent = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Svg} {...props} />
)

export default IconComponent
