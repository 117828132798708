import { DefaultHeaderKey } from '@/config'
import { create } from 'zustand'
import { menuKeysMap } from './menu'

type UserInfoT = Record<string, any>
interface GlobalState {
  menuKeysMap: any
  setGlobalInfo: (state: any) => void
  userInfo: UserInfoT
  setUserInfo: (userInfo: UserInfoT) => void
  collapsed: boolean
  setCollapsed: (status: boolean) => void
  authCodes: any[]
  setAuthCodes: (authInfo: any) => void
  getAuthCodes: () => any[]
  currentEnterprise: any // 当前企业
  setCurrentEnterprise: (currentEnterprise: any) => void
  // 是否经过授权
  authorized: boolean
  setAuthorized: (authorized: boolean) => void
  // 当前企业信息
  curEnterprise: any
  enterprisesList: any[]
  token: string // 鉴权token
  tenantId: string // 租户id，U开头代表个人
  preRoute: string // 上一个路由
  // 导航菜单
  curHeaderMenuKey: string
  // 设置顶部菜单，2种情况，直接点击顶部菜单，企业改变。
  setCurHeaderMenuKey: (v: any) => void
  getCurHeaderMenuKey: () => any
  resetGlobalStore: () => void
  // 后端记录的最后一次登录的 tenantId
  lastLoginTenantId: string | null
  unitConvertMap: any // {标准单位: 换算单位}
  unitAliasMap: any // {标准单位: [别名1，别名2]}
  getConverFactor: (a, b) => string
}

const initialState: Partial<GlobalState> = {
  menuKeysMap,
  // 用户相关信息
  userInfo: null,
  // 菜单收缩
  collapsed: false,
  authCodes: [],
  currentEnterprise: null,
  authorized: false,
  curEnterprise: null,
  enterprisesList: [],
  preRoute: '',
  // 登录相关信息
  token: '', // 鉴权token
  tenantId: '', // 租户id，U开头代表个人
  curHeaderMenuKey: DefaultHeaderKey,
  lastLoginTenantId: null,
  unitConvertMap: null,
  unitAliasMap: null,
}

const useGlobalStore = create<Partial<GlobalState>>()((set, get) => ({
  ...initialState,
  resetGlobalStore: () => {
    set(initialState)
  },
  setGlobalInfo: state => set({ ...get(), ...state }),
  // 设置用户信息
  setUserInfo: (userInfo: UserInfoT) => set({ userInfo: { ...userInfo } }),
  setCollapsed: status => set({ collapsed: status }),
  setAuthCodes: (authCodes: any) => set({ authCodes: [...authCodes] }),
  getAuthCodes: () => get().authCodes,
  setCurrentEnterprise: currentEnterprise => set({ currentEnterprise }),
  setAuthorized: authorized => set({ authorized }),
  setCurHeaderMenuKey: curHeaderMenuKey => set({ curHeaderMenuKey }),
  getCurHeaderMenuKey: () => get().curHeaderMenuKey,

  // 获取单位换算系数
  getConverFactor(originUnit, targetUnit) {
    // 获取标准单位
    let originStandardUnit = get().unitAliasMap[originUnit]
    if (!originStandardUnit) return ''

    let targetStandardUnit = get().unitAliasMap[targetUnit]
    if (!targetStandardUnit) return ''

    // 相等返回1
    if (String(originStandardUnit).trim() === String(targetStandardUnit).trim()) return '1'
    // 获取系数
    const targetFactor = get().unitConvertMap[`${originStandardUnit}_${targetStandardUnit}`]
    return targetFactor || ''
  }
}))

export default useGlobalStore
