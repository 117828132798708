import Icon from '@ant-design/icons'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

/* eslint-disable */
const Svg = () => (
  // eslint-disable-next-line
  <svg
    viewBox='0 0 1024 1024'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    p-id='9774'
    width='16'
    height='16'
    data-spm-anchor-id='a2cl9.flow_devops2020_goldlog.0.i15.25e946f6MtlJoo'
  >
    <path
      d='M864 760.6784v79.9744H160v-79.9744h704z m0-577.3312v79.9744H160V183.3472h704z m-169.2672 152.8832l148.8384 148.8896 0.8192 0.768c14.848 15.7184 14.5408 40.448-0.8192 55.808l-148.8384 148.8384-56.576-56.576 78.2848-78.336-556.544-1.6384 0.2048-79.9744 560.9472 1.6896-82.8928-82.8928 56.576-56.576z'
      p-id='9775'
    ></path>
  </svg>
)

const IconComponent = (props: Partial<CustomIconComponentProps> | any) => (
  <Icon component={Svg} {...props} />
)

export default IconComponent
