import React, { useMemo } from 'react'
import { useLocation, useNavigation } from 'react-router-dom'
import { useAuth } from '@/hooks/useAuth'
import Forbidden from '@/pages/Forbidden'
import useGlobalStore from '@/store/useGlobalStore'
import Loading from '@/pages/other/Loading'
import { getAuthPathByCodes } from '@/utils/help'
import { getStorage } from '@/utils/tools'

const ProtectedRoute = ({ children, style }: any) => {
  const location = useLocation()
  const { authorized } = useGlobalStore()
  const { codes } = useAuth()
  const authPaths = useMemo(() => getAuthPathByCodes(codes), [codes.length])
  const currentPathName = location.pathname
  if (!authorized) {
    return <Loading />
  }
  if (authPaths.includes(currentPathName) ) {
    return children
  }
  return <Forbidden style={style} />
}

export default ProtectedRoute
