import React, { memo, useState, useEffect, useCallback } from 'react'
import { Spin } from 'antd'
import styles from './loading.module.less'

const Loading = () => {
  return (
    <div className={styles.container}>
      <Spin />
    </div>
  )
}

export default Loading
